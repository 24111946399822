import React, { useState } from "react";
import { Typography, Alert } from "@mui/material";
import MButton from "components/shared/MButton";
import KycTextInput from "components/shared/KycTextInput";
import styles from "./styles";
import MLink from "components/shared/Link";
import Timer from "components/shared/Timer";

const AadharOtp = ({
  onSubmitOtpClick,
  onReenterAadhar,
  onResendCodeClick,
  isLoading,
  otpErrorText,
  resetOtpErrorText,
  showOtpSent,
  setShowOtpSent,
}) => {
  const [otp, setOtp] = useState("");
  const [isOtpLoading, setIsOtpLoading] = useState(false);
  const [otpTimer, setOtpTimer] = useState(false);
  const classes = styles();

  const resendOtpAndHandleLoading = async () => {
    setIsOtpLoading(true);
    try {
      await onResendCodeClick();
    } catch {}
    setIsOtpLoading(false);
  };

  return (
    <div className={classes.container}>
      <Typography className={classes.text}>
        {
          "Please enter the 6-digit code sent to your registered mobile number linked with Aadhaar"
        }
      </Typography>
      <div>
        <KycTextInput
          text={otp}
          setText={(text) => {
            setOtp(text);
            otpErrorText && resetOtpErrorText();
            setShowOtpSent(false);
          }}
          placeholder={"Enter code"}
          errorText={otpErrorText}
          maxLength={6}
          autoCapitalize={false}
        />
      </div>
      {showOtpSent && (
        <div style={{ marginBottom: 10 }}>
          <Alert severity="success">
            <Typography variant="alert">{"OTP sent successfully"}</Typography>
          </Alert>
        </div>
      )}
      <MButton
        onPress={() => onSubmitOtpClick(otp)}
        text={"Submit"}
        isPurpleButton
        size="medium"
        disabled={otp === ""}
        loading={isLoading}
      />
      <MLink
        text="Resend OTP"
        onClick={() => {
          resendOtpAndHandleLoading();
          setOtpTimer(true);
        }}
        loading={isOtpLoading}
        disabled={isOtpLoading || otpTimer}
        leading={
          otpTimer ? (
            <Timer
              timeInSeconds={30}
              onEnd={() => {
                setOtpTimer(false);
              }}
            />
          ) : null
        }
      />
      <MLink
        text="Re-enter your Aadhaar"
        onClick={() => {
          onReenterAadhar();
          setOtp("");
        }}
      />
    </div>
  );
};

export default AadharOtp;
