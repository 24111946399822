import { Button, Radio, Typography } from "@mui/material";
import bankMethod from "assets/images/bank-transfer.png";
import upiMethodGooglePay from "assets/images/gpay.png";
import upiMethodPaytm from "assets/images/paytm.png";
import upiMethodPhonePe from "assets/images/phonepe.png";
import upiMethod from "assets/images/upi-method.png";
import MRow from "components/shared/MRow";
import { ConfigContext } from "context/ConfigContext";
import { useContext, useEffect } from "react";
import colors from "styles/colors";

const PaymentMethod = ({ method, onClick, currentMethod, background }) => {
  const configContext = useContext(ConfigContext);

  let successRate =
    configContext?.config?.messages?.payment_method?.success_rate ?? {};
  let defaultSelectedPaymentMethod =
    configContext?.config?.features?.payment_method?.default ?? null;
  const payment_icons = (methodName) => {
    switch (methodName) {
      case "upi_transfer":
        return [upiMethod];
      case "other_upi_intent":
        return [upiMethod];
      case "gpay_transfer":
        return [upiMethodGooglePay];
      case "paytm_transfer":
        return [upiMethodPaytm];
      case "phonepe_transfer":
        return [upiMethodPhonePe];
      case "bank_transfer":
        return [bankMethod];
      default:
        return [upiMethod];
    }
  };

  let options = method?.options
  if (options) {
    // const optionsByIntent = groupBy(method?.options, (option) => option.name.includes("intent") ? "intent" : "code-flow")
    // const isMobile = isPlatformMobile()
    // const allowIntent = configContext?.config?.features?.payment_method?.allow_intent
    // options = isMobile && allowIntent ? optionsByIntent["intent"] : optionsByIntent["code-flow"]
  }

  useEffect(() => {
    options?.map((option) => {
      if (option?.name === defaultSelectedPaymentMethod) {
        onClick(option?.name);
      }
    });
  }, []);


  return (
    <div>
      <div
        style={{
          width: "100%",
          flex: 1,
          display: "flex",
        }}
      >
        <Typography
          style={{ fontSize: 14, fontWeight: 600 }}
        >{`${method?.category_display_name}`}</Typography>
      </div>
      <div style={{ padding: "0px 10px 10px 10px" }}>
        {options?.map((option, index) => {
          return (
            <div
              key={index}
              style={{
                borderBottomWidth: index + 1 === options.length ? 0 : 1,
                borderColor: colors.Grey.borderGrey,
              }}
            >
              <Button
                key={index}
                onClick={() => {
                  onClick(option?.name);
                }}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  background: "#FFFFFF",
                  justifyContent: "flex-start",
                  borderBottomWidth:
                    index + 1 === options.length ? 0 : 1,
                  width: "100%",
                  borderColor: colors.Grey.borderGrey,
                  ...background,
                  padding: "4px 0px 4px 0px",
                  marginBottom: 4,
                  marginTop: 4,
                  paddingLeft: 0,
                  paddingRight: 0,
                }}
                sx={{
                  textTransform: "none !important",
                }}
              >
                <MRow
                  leftElement={
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <Radio
                        size="small"
                        checked={currentMethod === option?.name}
                        onChange={() => {
                          onClick(option?.name);
                        }}
                        value="b"
                        name="radio-buttons"
                        style={{ paddingLeft: 0 }}
                        inputProps={{ "aria-label": "B" }}
                      />
                      <div
                        style={{
                          lineHeight: "150%",
                          display: "flex",
                          alignItems: "flex-start",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          style={{
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontWeight: "500",
                            fontSize: "15px",
                            lineHeight: "150%",
                            display: "flex",
                            alignItems: "center",
                            fontFeatureSettings:
                              "'cv01' on, 'cv08' on, 'cv05' on",
                            color: "black",
                          }}
                        >
                          {option?.display_name}
                        </Typography>
                        <Typography variant="caption" color={colors.Grey.title}>
                          {successRate?.[option?.name]}
                        </Typography>
                      </div>
                    </div>
                  }
                  rightElement={payment_icons(option?.name).map(
                    (image, index) => {
                      return (
                        <div
                          style={{ display: "flex", marginTop: 4 }}
                          key={index}
                        >
                          <img
                            alt="payment-method"
                            style={{
                              height: 35,
                              borderWidth: 1,
                              borderColor: colors.Grey.borderGrey,
                              borderRadius: 6,
                            }}
                            src={image}
                          />
                        </div>
                      );
                    }
                  )}
                />
              </Button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PaymentMethod;
