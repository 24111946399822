import React, { useState } from "react";
import PanDetails from "components/kyc/PanVerification";
import { getNameFromPAN, updateUserConsentForPan } from "api/kycHooks";

const PanDetailsContainer = ({ onStepCompleted, init, step }) => {
  const [showBottomSheet, setShowBottomSheet] = useState(false);
  const [name, setName] = useState("");
  const [panNumber, setPanNumber] = useState("");
  const [panErrors, setPanErrors] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = React.useState(false);

  const onContinueClick = async () => {
    setIsLoading(true);
    try {
      const { name: nameInPan } =
        (await getNameFromPAN({ pan_number: panNumber }, init)) || {};
      setName(nameInPan);
      setShowBottomSheet(true);
    } catch (err) {
      showErrorState(err);
    }
    setIsLoading(false);
  };

  const onError = async () => {
    setError(true);
  };

  const onRetry = async () => {
    setError(false);
  };

  const showErrorState = (err) => {
    const errorObj = err?.response?.data?.errors;
    const errorText = errorObj?.length
      ? errorObj[0]?.text
      : "Oops! We could not verify your PAN details";
    setPanErrors(errorText);
  };

  const onReenterPanClick = () => {
    setShowBottomSheet(false);
    setPanNumber("");
  };

  const onUserConsent = async () => {
    setIsLoading(true);
    try {
      await updateUserConsentForPan(
        {
          pan_number: panNumber,
          name,
        },
        init
      );
      onStepCompleted();
    } catch (err) {
      setShowBottomSheet(false);
      showErrorState("Something went wrong, Please try again later");
    }
    setIsLoading(false);
  };

  return (
    <PanDetails
      onContinueClick={onContinueClick}
      showBottomSheet={showBottomSheet}
      setShowBottomSheet={setShowBottomSheet}
      panNumber={panNumber}
      setPanNumber={setPanNumber}
      name={name}
      onReenterPanClick={onReenterPanClick}
      onUserConsent={onUserConsent}
      isLoading={isLoading}
      panErrors={panErrors}
      setPanErrors={setPanErrors}
      onError={onError}
      onRetry={onRetry}
      error={error}
      step={step}
    />
  );
};

export default PanDetailsContainer;
