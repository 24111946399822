import axios from "../utils/axiosInstance";

const headers = {
  "X-User-Required": true,
};

export const conversionBuyMenthods = async (init) => {
  const result = await axios({
    url: "v1/conversion/fiat",
    method: "GET",
    service: "wallet",
    params: {
      type: "buy",
    },
    init: init,
  });

  if (result?.data?.success) {
    return result.data?.data;
  }
  return Promise.reject("No data");
};

export const amountYouGet = async ({ init, fiat_symbol, crypto_symbol }) => {
  const result = await axios({
    url: `v1/conversion/fiat/price?fiat=${fiat_symbol}&crypto=${crypto_symbol}&type=buy`,
    service: "wallet",
    method: "GET",
    init: init,
  });

  if (result?.data?.success) {
    return result.data;
  }
  return Promise.reject("No data");
};

export const amountYouGetExternal = async ({
  init,
  fiat_symbol,
  crypto_symbol,
  to_amount,
  from_amount,
  network,
}) => {
  var url = `w/quote?from_currency=${fiat_symbol}&to_currency=${crypto_symbol}&network=${network}&`;
  url += to_amount ? `to_amount=${to_amount}` : `from_amount=${from_amount}`;

  const result = await axios({
    url: url,
    service: "walletV2Caas",
    method: "GET",
    init: init,
    headers: headers,
  });

  if (result?.data?.success) {
    return result.data;
  }
  return Promise.reject("No data");
};

export const getLimits = async ({ init, amount, fiat_symbol }) => {
  const result = await axios({
    url: `common/validate?event=buy&coin=USDT&amount=${amount}&fiat=${fiat_symbol}`,
    method: "GET",
    service: "wallet",
    init: init,
    headers: headers,
  });

  if (result?.data?.success) {
    return result.data;
  }
  return Promise.reject("No data");
};

export const fetchPaymentMethods = async ({
  init,
  currencyCode,
  kycCountry,
}) => {
  const result = await axios({
    url: `v1/conversion/fiat/methods?fiat=${currencyCode}&crypto=USDT&type=buy&country=${kycCountry}`,
    method: "GET",
    service: "wallet",
    init: init,
    headers: {
      "X-Version": 1,
    },
  });

  if (result?.data?.success) {
    return result.data;
  }
  return Promise.reject("No data");
};

export const fetchPaymentCards = async ({ init }) => {
  const result = await axios({
    url: `v1/conversion/fiat/user/payment-instrument?instrument_type=card`,
    method: "GET",
    service: "wallet",
    init: init,
    headers: headers,
  });

  if (result?.data?.success) {
    return result.data;
  }
  return Promise.reject("No data");
};

export const fetchBankAccountDetails = async ({
  init,
  bankPaymentMethod,
  fiat_symbol,
}) => {
  const result = await axios({
    url: `v1/conversion/fiat/methods/${bankPaymentMethod}/beneficiary?type=buy&fiat=${fiat_symbol}&crypto=USDT`,
    method: "GET",
    service: "wallet",
    init: init,
    headers: headers,
  });

  if (result?.data?.success) {
    return result.data;
  }
  return Promise.reject("No data");
};

export const makeTransaction = async ({ init, data }) => {
  const result = await axios({
    url: "v1/conversion/fiat/buy",
    method: "POST",
    service: "wallet",
    init: init,
    data: data,
    headers: headers,
  });

  if (result?.data?.success) {
    return result.data;
  }
  return Promise.reject("No data");
};

export const makeOnRampWithdrawTransaction = async ({ init, data }) => {
  const result = await axios({
    url: "crypto/buy",
    method: "POST",
    service: "walletCaas",
    init: init,
    data: data,
    headers: headers,
  });

  if (result?.data?.success) {
    return result.data;
  }
  return Promise.reject("No data");
};

export const getOnRampWithdrawTransaction = async ({ init, id }) => {
  try {
    const result = await axios({
      url: `w/transaction/buy/${id}`,
      method: "GET",
      service: "walletV2",
      init: init,
      headers: headers,
    });

    if (result?.data?.success) {
      return result.data;
    }
    return Promise.reject("No data");
  } catch (e) {
    return Promise.reject("No data");
  }
};

export const getOnRampTransaction = async ({ init, id }) => {
  try {
    const result = await axios({
      url: `conversion/fiat/transaction/${id}`,
      method: "GET",
      service: "walletCaas",
      init: init,
      headers: headers,
    });

    if (result?.data?.success) {
      return result.data;
    }
    return Promise.reject("No data");
  } catch (e) {
    return Promise.reject("No data");
  }
};

export const updateOnRampWithdrawTransaction = async ({
  init,
  orderId,
  utr,
  verification_code,
}) => {
  try {
    const result = await axios({
      url: `crypto/buy`,
      method: "PUT",
      service: "walletCaas",
      init: init,
      data: {
        transaction_id: orderId,
        ...(utr && { bank_transaction_id: utr }),
        ...(verification_code && { verification_code: verification_code }),
      },
      headers: headers,
    });

    if (result?.data?.success) {
      return result.data;
    }
    return Promise.reject(result.data);
  } catch (e) {
    return Promise.reject(e.response.data);
  }
};
