import React, { useEffect, useState } from "react";
import copy from "copy-to-clipboard";
import UPITransferMethod from "components/onRamp/UPITransferMethod";
import LoadingScreen from "components/shared/LoadingScreen";
import {
  fetchBankAccountDetails,
  makeTransaction,
  makeOnRampWithdrawTransaction,
  updateOnRampWithdrawTransaction,
} from "api/onRampHooks";
import StateEnum from "../states";

const UPITransferContainer = ({
  init,
  selectedCurrency,
  inputAmount,
  finalAmountYouGet,
  paymentMethods,
  bankId,
  onSuccess,
  external,
  externalNetwork,
  walletAddress,
  orderId,
  cryptoAmount,
  setCurrentState,
  setPaymentDetails,
  transactionId,
  selectedPaymentApp,
}) => {
  const [bankAccountDetails, setBankAccountDetails] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const bankPaymentMethodObj = paymentMethods?.UPI?.options;
  bankPaymentMethodObj[0].name = "upi_transfer";
  const bankPaymentMethod = bankPaymentMethodObj?.length
    ? bankPaymentMethodObj[0]?.name
    : "";

  useEffect(() => {
    setLoading(true);
    const fetchBankDetails = async () => {
      const accountDetailsRequest = await fetchBankAccountDetails({
        init: init,
        bankPaymentMethod: bankPaymentMethod,
        fiat_symbol: selectedCurrency?.fiat_symbol,
      });

      if (accountDetailsRequest.success) {
        setBankAccountDetails(accountDetailsRequest.data);
      } else {
        setError("Please try again after some time.");
      }
      setLoading(false);
    };

    try {
      fetchBankDetails();
    } catch (err) {
      setError("Please try again after some time.");
      setLoading(false);
    }
  }, []);

  const handleCopy = (type) => {
    copy(bankAccountDetails[type]);
  };

  const handleTransfer = async () => {
    setLoading(true);
    const requestData = external
      ? {
          verification_code: bankAccountDetails?.code,
          source_id: bankId,
          from_currency: selectedCurrency?.fiat_symbol,
          to_currency: "USDT",
          from_amount: inputAmount,
          to_amount: cryptoAmount,
          payment_method: bankPaymentMethod,
          network: externalNetwork,
          crypto_wallet_address: walletAddress,
          ...(transactionId && {
            uuid: transactionId,
          }),
        }
      : {
          verification_code: bankAccountDetails?.code,
          source_id: bankId,
          fiat_symbol: selectedCurrency?.fiat_symbol,
          crypto_symbol: "USDT",
          fiat_amount: inputAmount,
          crypto_amount: finalAmountYouGet,
          payment_method: bankPaymentMethod,
          ...(transactionId && {
            uuid: transactionId,
          }),
        };

    try {
      const buyTransaction = orderId
        ? await updateOnRampWithdrawTransaction({
            init,
            orderId: orderId,
            verification_code: bankAccountDetails?.code,
          })
        : external
        ? await makeOnRampWithdrawTransaction({
            init: init,
            data: requestData,
          })
        : await makeTransaction({
            init: init,
            data: requestData,
          });

      setCurrentState(StateEnum.SUCCESS_SCREEN);
      setPaymentDetails(buyTransaction.data);
      setLoading(false);
      onSuccess();
      return buyTransaction?.data?.id;
    } catch (err) {
      setError("Please try again after some time.");
      setLoading(false);
    }
  };

  return inputAmount && bankAccountDetails && bankPaymentMethod ? (
    <UPITransferMethod
      init={init}
      amount={inputAmount}
      handleCopy={handleCopy}
      handleTransfer={handleTransfer}
      accountDetails={bankAccountDetails}
      loading={loading}
      error={error}
      selectedCurrency={selectedCurrency}
      selectedPaymentApp={selectedPaymentApp}
    />
  ) : (
    <LoadingScreen headerVisible={false} />
  );
};

export default UPITransferContainer;
