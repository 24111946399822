import React, { useEffect, useState } from "react";
import BankTransferMethod from "components/onRamp/BankTransferMethod";
import copy from "copy-to-clipboard";
import {
  fetchBankAccountDetails,
  makeTransaction,
  makeOnRampWithdrawTransaction,
  updateOnRampWithdrawTransaction,
} from "../../../api/onRampHooks";
import LoadingScreen from "../../../components/shared/LoadingScreen";
import StateEnum from "../states";

const BankTransferContainer = ({
  init,
  selectedCurrency,
  inputAmount,
  finalAmountYouGet,
  paymentMethods,
  bankId,
  accountNumber,
  external,
  externalNetwork,
  walletAddress,
  orderId,
  cryptoAmount,
  orderDetails,
  setCurrentState,
  setPaymentDetails,
  transactionId,
}) => {
  const [bankAccountDetails, setBankAccountDetails] = useState({});
  const [error, setError] = useState(null);
  const [UTR, setUTR] = useState("");
  const [loading, setLoading] = useState(false);
  const bankPaymentMethodObj = paymentMethods?.BANK_TRANSFER?.options;
  const bankPaymentMethod = bankPaymentMethodObj?.length
    ? bankPaymentMethodObj[0]?.name
    : "";

  useEffect(() => {
    setLoading(true);
    const fetchBankDetails = async () => {
      const accountDetailsRequest = await fetchBankAccountDetails({
        init: init,
        bankPaymentMethod: bankPaymentMethod || orderDetails?.payment_method,
        fiat_symbol: selectedCurrency?.fiat_symbol || orderDetails?.fiat_symbol,
      });

      if (accountDetailsRequest.success) {
        setBankAccountDetails(accountDetailsRequest.data);
      } else {
        setError("Please try again after some time.");
      }
      setLoading(false);
    };

    try {
      fetchBankDetails();
    } catch (err) {
      setError("Please try again after some time.");
      setLoading(false);
    }
  }, []);

  const handleCopy = (type) => {
    copy(bankAccountDetails[type]);
  };

  const handleTransfer = async () => {
    const isUTRInvalid =
      UTR.length < 5 ||
      UTR === "12345" ||
      UTR === "54321" ||
      UTR === "98765" ||
      UTR === "56789";
    if (UTR && !isUTRInvalid) {
      if (!loading) {
        setError("");
        setLoading(true);
        const data = external
          ? {
              bank_transaction_id: UTR,
              source_id: bankId,
              from_currency: selectedCurrency?.fiat_symbol,
              to_currency: "USDT",
              from_amount: inputAmount,
              to_amount: cryptoAmount,
              payment_method: bankPaymentMethod,
              network: externalNetwork,
              crypto_wallet_address: walletAddress,
              ...(transactionId && {
                uuid: transactionId,
              }),
            }
          : {
              bank_transaction_id: UTR,
              source_id: bankId,
              fiat_symbol: selectedCurrency?.fiat_symbol,
              crypto_symbol: "USDT",
              fiat_amount: inputAmount,
              crypto_amount: finalAmountYouGet,
              payment_method: bankPaymentMethod,
              ...(transactionId && {
                uuid: transactionId,
              }),
            };

        try {
          const bankTransferBuyRequest = orderId
            ? await updateOnRampWithdrawTransaction({
                init,
                orderId: orderId,
                utr: UTR,
              })
            : external
            ? await makeOnRampWithdrawTransaction({
                init: init,
                data: data,
              })
            : await makeTransaction({
                init: init,
                data: data,
              });
          setCurrentState(StateEnum.SUCCESS_SCREEN);
          setPaymentDetails(bankTransferBuyRequest.data);
          setLoading(false);
        } catch (err) {
          setError("Please try again after some time.");
          setLoading(false);
        }
      }
    } else {
      if (!UTR) {
        setError("This field cannot be empty.");
      } else if (isUTRInvalid) {
        setError("Invalid details entered, Please try again.");
      }
    }
  };

  return inputAmount && bankAccountDetails && bankPaymentMethod ? (
    <BankTransferMethod
      error={error}
      UTR={UTR}
      amount={orderDetails ? orderDetails?.fiat_amount : inputAmount}
      loading={loading}
      accountDetails={bankAccountDetails}
      handleTransfer={handleTransfer}
      setUTR={setUTR}
      handleCopy={handleCopy}
      setError={setError}
      bankPaymentMethod={bankPaymentMethod}
      selectedCurrency={selectedCurrency}
      accountNumber={accountNumber}
    />
  ) : (
    <LoadingScreen headerVisible={false} />
  );
};

export default BankTransferContainer;
