import { useGetTransactions } from "api/profile/useGetTransactions";
import { TransactionDetail } from "components/profile/transactions/transaction-detail";
import TransactionListItem from "components/profile/transactions/transaction-list-item";
import BaseLayout from "components/shared/BaseLayout";
import { LoadingView } from "components/shared/LoadingScreen";
import { FC, useState } from "react";
import { Transaction, TransactionType } from "types/transaction";
import EmptyState from "assets/svgs/empty-transactions.svg";
import { Typography } from "@mui/material";
import colors from "styles/colors";
interface TransactionListProps {}

export const TransactionList: FC<TransactionListProps> = (props) => {
  const {} = props;
  const { transactions, isFetching, errors } = useGetTransactions();
  const [transaction, setTransaction] = useState<Transaction | null>(null);
  const isEmpty = transactions.length == 0;

  const onClick = (transaction: Transaction | null) => {
    setTransaction(transaction);
  };

  const onClose = () => {
    setTransaction(null);
  };

  return (
    <BaseLayout
      headerTitle={"Transactions"}
      headerVisible={true}
      body={
        <div id="body" style={{ height: "100%" }}>
          <div style={{ height: "100%" }}>
            {isFetching ? (
              <LoadingView />
            ) : transaction != null ? (
              <TransactionDetail
                transactionId={transaction.id}
                external={
                  transaction.type == TransactionType.ONRAMP_AND_WITHDRAW
                }
                onClose={onClose}
              />
            ) : !isEmpty ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  overflow: "scroll",
                  height: "100%",
                }}
              >
                {transactions.map((txn) => (
                  <TransactionListItem
                    key={txn.id}
                    transaction={txn}
                    onClick={onClick}
                  />
                ))}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <img src={EmptyState} width={115} height={130} />
                <Typography
                  style={{
                    paddingTop: 20,
                    fontSize: 14,
                    fontWeight: 400,
                    color: colors.Grey.title,
                  }}
                >
                  Your transactions will be shown here.
                </Typography>
              </div>
            )}
          </div>
        </div>
      }
      footer={undefined}
      bottomSheet={undefined}
    />
  );
};

TransactionList.propTypes = {};
