import { plainToInstance } from "class-transformer";
import useNetworkRequest from "hooks/networking/useNetworkRequest";
import { Transaction } from "types/transaction";

export function useGetTransactions() {
  const requestConfig: any = {
    url: "transactions",
    method: "GET",
    service: "walletV2Caas",
    params: { transaction_type: "ONRAMP_AND_WITHDRAW,BUY", limit: 100 },
    data: null,
  };
  const { data, isFetching, errors } = useNetworkRequest(requestConfig);
  let transactions: Array<Transaction> = [];
  if (data != null) {
    transactions = plainToInstance(Transaction, data["transactions"]);
  }

  return { transactions, isFetching, errors };
}
