import React from "react";
import { Typography, Alert } from "@mui/material";
import MButton from "components/shared/MButton";
import { Done } from "@mui/icons-material";
import styles from "./styles";
import BaseLayout from "components/shared/BaseLayout";
import MHeading from "components/shared/MHeading";

const LiveImage = ({ onContinueClick, isLoading, error, step }) => {
  const classes = styles();
  return (
    <BaseLayout
      theme={"primary"}
      headerTitle={"Verify Account"}
      headerVisible={true}
      steps={step}
      body={
        <div id="body">
          <MHeading
            heading="Take a selfie"
            subHeading="We will compare it with your Aadhaar information"
          />
          <Typography className={classes.title2}>{"Guidelines"}</Typography>
          <div className={classes.detailsContainer}>
            <div className={classes.detailRow}>
              <Done
                color="success"
                fontSize="small"
                style={{ marginRight: 5 }}
              />
              <Typography variant="subtitle2">
                {"Face forward towards camera"}
              </Typography>
            </div>
            <div className={[classes.detailRow]}>
              <Done
                color="success"
                fontSize="small"
                style={{ marginRight: 5 }}
              />
              <Typography variant="subtitle2">
                {"Make sure your eyes are clearly visible"}
              </Typography>
            </div>
            <div className={classes.detailRow}>
              <Done
                color="success"
                fontSize="small"
                style={{ marginRight: 5 }}
              />
              <Typography variant="subtitle2">
                {"Remove your glasses, if applicable"}
              </Typography>
            </div>
          </div>
          {error && (
            <div style={{ marginBottom: 10 }}>
              <Alert severity="success">
                <Typography variant="alert">{error}</Typography>
              </Alert>
            </div>
          )}
        </div>
      }
      footer={
        <MButton
          onPress={onContinueClick}
          loading={isLoading}
          text={"Take Selfie"}
          size="medium"
        />
      }
    />
  );
};

export default LiveImage;
