import React, { useEffect, useState } from "react";
import PhoneVerificationContainer from "pages/kyc/PhoneVerification";
import EmailVerificationContainer from "pages/kyc/EmailVerification";

const PhoneEmailVerification = ({
  init,
  emailVerified,
  phoneVerified,
  onStepCompleted,
}) => {
  const [state, setState] = useState(null);

  useEffect(() => {
    if (!phoneVerified) {
      setState("PHONE");
    } else {
      setState("EMAIL");
    }
  }, []);

  const states = (state) => {
    switch (state) {
      case "PHONE":
        return (
          <PhoneVerificationContainer
            init={init}
            onStepCompleted={() => {
              if (!emailVerified) {
                setState("EMAIL");
              } else {
                onStepCompleted();
              }
            }}
          />
        );
      case "EMAIL":
        return (
          <EmailVerificationContainer
            init={init}
            onStepCompleted={() => {
              onStepCompleted();
            }}
          />
        );
    }
  };
  return states(state);
};

export default PhoneEmailVerification;
