import { BackdropProps, Drawer, Backdrop as MuiBackdrop, DrawerProps as MuiDrawerProps, styled } from '@mui/material';
import React from 'react';
import { FC } from 'react';

// adopted from https://github.com/mui/material-ui/issues/11749#issuecomment-934825888

const Backdrop = styled(MuiBackdrop)<BackdropProps>(({ theme }) => ({
    position: 'absolute',
}));

type DrawerProps = MuiDrawerProps & {
    onClose: React.ReactEventHandler<{}>
}

export const InsideDrawer: FC<DrawerProps> = (props) => {
    return (
        <div>
            <Backdrop
                onClick={props.onClose}
                open={props.open ?? false}
            />
            <Drawer
                {...props}
                PaperProps={{
                    ...props.PaperProps,
                    sx: {
                        ...props.PaperProps?.sx,
                        position: 'absolute',
                    }
                }}
                variant="persistent"
            />
        </div>
    );
};