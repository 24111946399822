import { Expose } from "class-transformer"

export class User {

    @Expose({ name: "user_uuid" }) userId: string
    @Expose({ name: "name" }) name: string
    @Expose({ name: "email" }) email: string
    @Expose({ name: "phone" }) phone: string
    @Expose({ name: "kyc_status" }) kycStatus: string

    constructor(
        userId: string,
        name: string,
        email: string,
        phone: string,
        kycStatus: string
    ) {
        this.userId = userId
        this.name = name
        this.email = email
        this.phone = phone
        this.kycStatus = kycStatus
    }

    isKycVerified() {
        return this.kycStatus.toUpperCase() == "VERIFIED"
    }
}