import BaseLayout from "components/shared/BaseLayout";
import KycTextInput from "components/shared/KycTextInput";
import React, { useState } from "react";
import MHeading from "components/shared/MHeading";
import MButton from "components/shared/MButton";
import ConfirmEmail from "components/kyc/ConfirmEmail";
import MBottomSheet from "components/shared/BottomSheet";

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const EmailVerification = ({
  email,
  setEmail,
  isBottomsheetVisible,
  setIsBottomsheetVisible,
  onSubmitEmail,
  error,
  setError,
  loading,
  bottomsheetLoading,
  otp,
  setOtp,
  onSubmitEmailOtp,
  onReenterEmail,
  onResendOtp,
  otpError,
  otpSuccess,
  setOtpError,
  setOtpSucces,
  resendLoading,
  otpTimer,
  setOtpTimer,
}) => {
  const [isValidEmail, setIsValidEmail] = useState(false);
  return (
    <BaseLayout
      theme={"primary"}
      headerTitle={"Verify Account"}
      headerVisible={true}
      body={
        <div id="body">
          <div id="body">
            <MHeading
              heading={"Enter your email"}
              subHeading={"Adding a email makes your account more secure"}
            />
            <KycTextInput
              placeholder={"johndoe@abc.com"}
              text={email}
              maxLength={50}
              setText={(text) => {
                if (!emailRegex.test(text)) {
                  setIsValidEmail(false);
                } else {
                  setIsValidEmail(true);
                }
                setError(null);
                setEmail(text);
              }}
              autoCapitalize={false}
              errorText={error}
            />
          </div>
        </div>
      }
      footer={
        <div id="footer">
          <MButton
            disabled={loading || !isValidEmail}
            onPress={onSubmitEmail}
            text={"Continue"}
            isPurpleButton
            size="medium"
            loading={loading}
          />
        </div>
      }
      bottomSheet={
        <MBottomSheet
          isVisible={isBottomsheetVisible}
          close={() => {
            setIsBottomsheetVisible(false);
          }}
          title="Confirm your email"
        >
          <ConfirmEmail
            otp={otp}
            setOtp={setOtp}
            onSubmitEmailOtp={onSubmitEmailOtp}
            isLoading={bottomsheetLoading}
            onReenterEmail={onReenterEmail}
            onResendOtp={onResendOtp}
            email={email}
            otpError={otpError}
            otpSuccess={otpSuccess}
            setOtpError={setOtpError}
            setOtpSucces={setOtpSucces}
            resendLoading={resendLoading}
            otpTimer={otpTimer}
            setOtpTimer={setOtpTimer}
          />
        </MBottomSheet>
      }
    />
  );
};

export default EmailVerification;
