import themeOptions from "../../styles/theme";
import { ThemeProvider } from "@mui/material/styles";
import Caas from "../../pages/caas";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";

const Success = ({}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const cryptoAmount = searchParams.get("crypto_amount");
  const transactionID = searchParams.get("transaction_id");
  const status = searchParams.get("status");

  const init = async () => {
    return {
      signature: searchParams.get("token"),
      timestamp: searchParams.get("ts"),
      userId: searchParams.get("user_uuid"),
      clientId: searchParams.get("client_id"),
      mode: searchParams.get("mode"),
      hv_token: searchParams.get("hv_token"),
    };
  };

  useEffect(() => {
    window.parent.postMessage(
      {
        event: "success",
        params: {
          crypto_amount: cryptoAmount,
          transaction_id: transactionID,
          status: status,
        },
      },
      "*"
    );
  }, []);

  // useEffect(() => {
  //   setTimeout(() => {
  //     var redirectToApp = function () {
  //       var scheme = "appnameapp";
  //       var openURL =
  //         "appname" +
  //         window.location.pathname +
  //         window.location.search +
  //         window.location.hash;
  //       var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  //       var Android = /Android/.test(navigator.userAgent);
  //       var newLocation;
  //       if (iOS) {
  //         newLocation = redirectUrl;
  //       } else if (Android) {
  //         newLocation =
  //           "intent://" +
  //           openURL +
  //           "#Intent;scheme=" +
  //           scheme +
  //           ";package=com.appnameapp;end";
  //       } else {
  //         newLocation = redirectUrl;
  //       }
  //       if (!Android || !iOS) {
  //         if (redirectUrl.includes("?")) {
  //           redirectUrl = `+crypto_amount=${cryptoAmount}&transaction_id=${transactionID}&status=${status}`;
  //         } else {
  //           redirectUrl = `?crypto_amount=${cryptoAmount}&transaction_id=${transactionID}&status=${status}`;
  //         }
  //         window.parent.location.href = redirectUrl;
  //       } else {
  //         window.location.replace(redirectUrl);
  //       }
  //     };
  //     redirectUrl && redirectToApp();
  //   }, 1000);
  // }, []);

  return (
    <>
      <ThemeProvider theme={themeOptions}>
        <Caas
          init={init}
          sdkConfiguration={{
            steps: ["success"],
            kycEnabled: true,
            bankEnabled: true,
          }}
          onKYCSuccessCallback={() => {}}
          onRampSuccessCallback={() => {}}
          offRampSuccessCallback={() => {}}
          onExceptionCallback={() => {
            window.location.reload();
          }}
          exceptionCTA={"Try again"}
          fiatAmount={searchParams.get("fiat_amount")}
          startCustomKYCCallback={() => {}}
          onBack={() => {}}
          withdraw={searchParams.get("withdraw")}
          withdrawNetwork={searchParams.get("withdraw_network")}
          withdrawAddress={searchParams.get("withdraw_address")}
        />
      </ThemeProvider>
    </>
  );
};

export default Success;
