import React, { useState } from "react";
import styles from "./styles";
import currenciesFlag from "./FlagsImportFile";
import MButton from "components/shared/MButton";
import { Typography, Button, Alert } from "@mui/material";
import KycTextInput from "components/shared/KycTextInput";
import colors from "styles/colors";
import BaseLayout from "components/shared/BaseLayout";
import UPI from "assets/svgs/upi.svg";
import NEFT from "assets/svgs/neft.svg";
import IMPS from "assets/svgs/imps.svg";
import MBottomSheet from "components/shared/BottomSheet";
import { makeOnRampWithdrawTransaction } from "api/onRampHooks";
import USDT from "assets/svgs/usdt.svg";
import StateEnum from "../states";
import { getLimits } from "api/onRampHooks";
import MRow from "components/shared/MRow";
const AddFunds = ({
  init,
  setCurrentState,
  selectedCurrency,
  inputAmount,
  setInputAmount,
  finalAmountYouGet,
  areConversionDetailsLoading,
  setCheckingLimits,
  showCrashError,
  checkingLimits,
  setShowCrashError,
  conversionDetails,
  selectedCryptoCurrency,
  isKycPending,
  startKYC,
  external,
  externalData,
  inputCryptoAmount,
  setInputCryptoAmount,
  walletAddress,
  setPaymentDetails,
  errors,
}) => {
  const classes = styles();
  // prettier-ignore
  const numbersReg = new RegExp("^[0-9]+[.]?[0-9]?[0-9]?$");
  const [loading, setLoading] = useState(false);
  const createTransaction = async () => {
    setLoading(true);
    try {
      const result = await makeOnRampWithdrawTransaction({
        init: init,
        data: externalData,
      });
      if (result?.success) {
        setPaymentDetails(result?.data);
        if (result?.data?.payment_method === "bank_transfer") {
          setCurrentState(StateEnum.BANK_PAYMENT_METHOD);
        } else if (result?.data?.payment_method === "upi_transfer") {
          setCurrentState(StateEnum.UPI_PAYMENT_METHOD);
        }
      }
    } catch (e) {}

    setLoading(false);
  };

  const checkBuyLimits = async () => {
    try {
      setCheckingLimits(true);
      const request = await getLimits({
        init: init,
        amount: inputAmount,
        fiat_symbol: selectedCurrency?.fiat_symbol,
      });

      if (request.success) {
        if (external && !walletAddress) {
          setCurrentState(StateEnum.WALLET_ADDRESS);
        } else {
          setCurrentState(StateEnum.PAYMENT_METHODS);
        }
        setShowCrashError(false);
      } else {
      }
      setCheckingLimits(false);
    } catch (err) {
      setCheckingLimits(false);
      setShowCrashError(true);
    }
  };

  return (
    <BaseLayout
      theme={"primary"}
      headerTitle={"Buy"}
      headerVisible={true}
      body={
        <div id="body">
          <div style={{ height: "100%" }}>
            <div style={styles.mainContainer}>
              <div style={styles.scrollViewContainer}>
                <div style={styles.container}>
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: 400,
                      color: colors.Grey.title,
                    }}
                  >
                    {"Purchase Amount"}
                  </Typography>
                  <div>
                    <div>
                      <KycTextInput
                        autoCapitalize={false}
                        style={{
                          paddingBottom: 20,
                          paddingTop: 20,
                        }}
                        textStyle={{ fontSize: 16, fontWeight: 600 }}
                        leading={
                          <Typography
                            style={{
                              backgroundColor: colors?.Background?.secondary,
                            }}
                            className={styles.currencySymbolInput}
                          >
                            {selectedCurrency?.currency_symbol}
                          </Typography>
                        }
                        trailing={
                          <div className={classes.currencyContainer}>
                            <img
                              alt="fiat-flag"
                              src={
                                currenciesFlag[
                                  selectedCurrency?.fiat_symbol
                                    .toLowerCase()
                                    .slice(0, 2)
                                ]
                              }
                              className={classes.flag}
                            />
                            <Typography
                              style={{
                                fontSize: 16,
                                fontWeight: 600,
                                color: colors.Dark.title,
                              }}
                            >
                              {selectedCurrency?.fiat_symbol}
                            </Typography>
                          </div>
                        }
                        textAlignVertical={"center"}
                        placeholder={"Enter Amount"}
                        placeholderTextColor={"#BCBCBC"}
                        text={`${inputAmount}`}
                        setText={(val) => {
                          if (numbersReg.test(val) || val === "") {
                            if (val === "") {
                              setInputAmount("");
                              setInputCryptoAmount("");
                              return;
                            }
                            setInputAmount(val);
                            var cryptoAmount = external
                              ? (
                                  val / conversionDetails?.base_price -
                                  conversionDetails?.fee_breakup?.network_fee
                                ).toFixed(2)
                              : (val * conversionDetails?.base_rate).toFixed(2);

                            setInputCryptoAmount(
                              cryptoAmount > 0 ? cryptoAmount : 0
                            );
                            setShowCrashError(false);
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div style={styles.container}>
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: 400,
                      color: colors.Grey.title,
                    }}
                  >
                    {"Asset being purchased"}
                  </Typography>
                  <div>
                    <div>
                      <KycTextInput
                        autoCapitalize={false}
                        style={{ paddingBottom: 20, paddingTop: 20 }}
                        textStyle={{ fontSize: 16, fontWeight: 600 }}
                        trailing={
                          <Button
                            disableElevation
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer",
                              padding: 0,
                            }}
                            sx={{
                              textTransform: "none !important",
                            }}
                            onClick={() => {}}
                          >
                            <div className={classes.currencyContainer}>
                              <img
                                alt="crypto-flag"
                                src={USDT}
                                className={classes.flag}
                              />
                              <Typography
                                style={{
                                  fontSize: 16,
                                  fontWeight: 600,
                                  color: colors.Dark.title,
                                  marginRight: 3,
                                }}
                              >
                                {externalData
                                  ? externalData?.to_currency
                                  : selectedCryptoCurrency?.crypto_symbol}
                              </Typography>
                            </div>
                          </Button>
                        }
                        textAlignVertical={"center"}
                        placeholder={"Enter Amount"}
                        placeholderTextColor={"#BCBCBC"}
                        text={`${
                          externalData ? inputCryptoAmount : finalAmountYouGet
                        }`}
                        setText={(val) => {
                          if (numbersReg.test(val) || val === "") {
                            if (val === "") {
                              setInputAmount("");
                              setInputCryptoAmount("");
                              return;
                            }
                            setInputCryptoAmount(val);
                            setInputAmount(
                              Math.ceil(
                                (
                                  (Number(val) +
                                    Number(
                                      conversionDetails?.fee_breakup
                                        ?.network_fee
                                    )) *
                                  conversionDetails?.base_price
                                ).toFixed(2)
                              )
                            );
                            setShowCrashError(false);
                          }
                        }}
                      />
                      <MRow
                        leftElement={
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "20px",
                            }}
                          >
                            <Typography
                              fontSize={12}
                              fontWeight={400}
                              color={colors.Grey.title}
                            >
                              {`${
                                external ? externalData?.to_currency : "USDT"
                              } ${1} = ${
                                external
                                  ? conversionDetails?.base_price
                                  : conversionDetails?.price
                              } ${selectedCurrency?.fiat_symbol}`}
                            </Typography>
                          </div>
                        }
                        rightElement={
                          external && (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                                marginBottom: "20px",
                              }}
                            >
                              <Typography
                                fontSize={12}
                                fontWeight={400}
                                color={colors.Grey.title}
                              >
                                {`Network Fees = ${
                                  conversionDetails?.fee_breakup?.network_fee
                                } ${
                                  externalData
                                    ? externalData?.to_currency
                                    : selectedCryptoCurrency?.crypto_symbol
                                }`}
                              </Typography>
                            </div>
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      footer={
        <div className={classes.buttonContainer}>
          {errors?.conversionError && (
            <Typography variant="alert">
              <Alert severity="error">{errors?.conversionError}</Alert>
            </Typography>
          )}
          <div
            style={{
              margin: "15px 0px",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
            }}
          >
            <Typography
              style={{
                fontSize: 12,
                fontWeight: 400,
                color: colors.Grey.title,
              }}
            >
              Pay using
            </Typography>
            <div style={{ gap: 8, display: "flex", paddingLeft: 6 }}>
              <img alt="upi" src={UPI} />
              <img alt="imps" src={IMPS} />
              <img alt="neft" src={NEFT} />
            </div>
          </div>
          <MButton
            onPress={
              isKycPending
                ? startKYC
                : external && externalData?.payment_method
                ? createTransaction
                : checkBuyLimits
            }
            text={"Proceed"}
            isPurpleButton
            size="medium"
            loading={
              (external && externalData?.payment_method
                ? loading
                : checkingLimits) || areConversionDetailsLoading
            }
            disabled={
              !(
                inputAmount &&
                selectedCurrency?.status === 1 &&
                !showCrashError &&
                !areConversionDetailsLoading
              ) ||
              (external && externalData?.payment_method
                ? loading
                : checkingLimits) ||
              errors?.conversionError
            }
          />
        </div>
      }
      bottomSheet={<MBottomSheet />}
    />
  );
};

export default AddFunds;
