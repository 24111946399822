import React, { useState } from "react";
import PhoneVerification from "components/kyc/PhoneVerification";
import { sendPhoneOtp, verifyPhoneOtp } from "api/kycHooks";

const PhoneVerificationContainer = ({ init, onStepCompleted }) => {
  const [phone, setPhone] = useState(null);
  const [isBottomsheetVisible, setIsBottomsheetVisible] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [bottomsheetLoading, setBottomsheetLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState(null);
  const [otpSuccess, setOtpSucces] = useState(null);
  const [resendLoading, setResendLoading] = useState(false);
  const [otpTimer, setOtpTimer] = useState(false);

  const checkValidPhone = () => {
    return String(phone)
      .toLowerCase()
      .match(/^(\d{10})$/);
  };

  const onSubmitPhone = async () => {
    setLoading(true);
    try {
      const validation = checkValidPhone(phone);
      if (!validation) {
        setError("You have entered an invalid phone number!");
        throw "Invalid Phone";
      }
      const result = await sendPhoneOtp(phone, init);
      if (result?.success) {
        setError(null);
        setIsBottomsheetVisible(true);
      } else {
        setError(result?.error);
      }
    } catch (e) {}
    setLoading(false);
  };

  const onSubmitPhoneOtp = async () => {
    setBottomsheetLoading(true);
    try {
      const result = await verifyPhoneOtp(phone, otp, init);
      if (result?.success) {
        setOtpError(null);
        setOtpSucces("Phone verified successfully!");
        onStepCompleted();
      } else {
        setOtpSucces(null);
        setOtpError(result?.error);
      }
    } catch (e) {}
    setBottomsheetLoading(false);
  };

  const onResendOtp = async () => {
    setResendLoading(true);
    setOtp("");
    setOtpError(null);
    setOtpSucces(null);
    try {
      const result = await sendPhoneOtp(phone, init);
      if (result?.success) {
        setOtpSucces("Resent successfully!");
        setOtpError(null);
      } else {
        setOtpSucces(null);
        setOtpError(result?.error);
      }
    } catch (e) {}
    setResendLoading(false);
    setOtpTimer(true);
  };

  const onReenterPhone = async () => {
    setPhone("");
    setOtp("");
    setBottomsheetLoading(false);
    setLoading(false);
    setIsBottomsheetVisible(false);
    setOtpSucces(null);
    setOtpError(null);
  };

  return (
    <PhoneVerification
      phone={phone}
      setPhone={setPhone}
      isBottomsheetVisible={isBottomsheetVisible}
      setIsBottomsheetVisible={setIsBottomsheetVisible}
      onSubmitPhone={onSubmitPhone}
      error={error}
      setError={setError}
      loading={loading}
      bottomsheetLoading={bottomsheetLoading}
      otp={otp}
      setOtp={setOtp}
      onSubmitPhoneOtp={onSubmitPhoneOtp}
      onReenterPhone={onReenterPhone}
      onResendOtp={onResendOtp}
      otpError={otpError}
      otpSuccess={otpSuccess}
      setOtpError={setOtpError}
      setOtpSucces={setOtpSucces}
      resendLoading={resendLoading}
      otpTimer={otpTimer}
      setOtpTimer={setOtpTimer}
    />
  );
};

export default PhoneVerificationContainer;
