import { getUser } from 'api/profile/useGetUser';
import type { FC, ReactNode } from 'react';
import { createContext, useEffect, useState } from 'react';
import { User } from 'types/user';

type UserState = {
    user: User | null
    init: any
}

const initialState = {
    user: null,
    init: null
};

export const UserContext = createContext<UserState>(initialState);

type UserContextProviderProps = {
    init: any,
    children: ReactNode,
}

export const UserContextProvider: FC<UserContextProviderProps> = (props) => {

    const { children, init } = props
    const [state, setState] = useState<UserState>(initialState);

    useEffect(() => {
        const load = async () => {
            const user = await getUser(init);
            if (user != undefined && user != null) {
                setState({ user, init })
            }
        };
        load();
    }, []);

    return (
        <UserContext.Provider value={state} > {children} </UserContext.Provider>
    )
};
