import React from "react";
import MButton from "components/shared/MButton";
import { Typography, Alert } from "@mui/material";
import gs from "styles/styles";
import MLink from "components/shared/Link";
import KycTextInput from "components/shared/KycTextInput";
import Timer from "components/shared/Timer";

const ConfirmPhone = ({
  onSubmitPhoneOtp,
  otp,
  setOtp,
  isLoading,
  onReenterPhone,
  onResendOtp,
  phone,
  otpError,
  otpSuccess,
  setOtpError,
  setOtpSucces,
  resendLoading,
  otpTimer,
  setOtpTimer,
}) => {
  const globalStyles = gs();
  return (
    <div
      style={{
        flex: 1,
        height: "100%",
        width: "100%",
      }}
    >
      <div className={globalStyles.dialogContainer}>
        <div style={{ marginBottom: 10 }}>
          <Typography variant="h10" className={globalStyles.confirmText}>
            {`We’ve sent a verification code to your phone +91${phone}`}
          </Typography>
        </div>
        <div>
          <KycTextInput
            text={otp}
            setText={(text) => {
              setOtp(text);
              setOtpError(null);
              setOtpSucces(null);
            }}
            placeholder={"Enter code"}
            maxLength={6}
            autoCapitalize={false}
          />
        </div>
      </div>
      <div style={{ marginBottom: 10 }}>
        {otpError && (
          <Alert severity="error">
            <Typography variant="alert">{otpError}</Typography>
          </Alert>
        )}
        {otpSuccess && (
          <Alert severity="success">
            <Typography variant="alert">{otpSuccess}</Typography>
          </Alert>
        )}
      </div>
      <MButton
        onPress={onSubmitPhoneOtp}
        text={"Continue"}
        isPurpleButton
        size="medium"
        loading={isLoading}
        disabled={otp.length < 6}
      />
      <MLink
        text="Resend OTP"
        onClick={onResendOtp}
        loading={resendLoading}
        disabled={resendLoading || otpTimer}
        leading={
          otpTimer ? (
            <Timer
              timeInSeconds={30}
              onEnd={() => {
                setOtpTimer(false);
              }}
            />
          ) : null
        }
      />
      <MLink
        text="Re-enter Phone"
        onClick={onReenterPhone}
        loading={false}
        disabled={false}
      />
    </div>
  );
};

export default ConfirmPhone;
