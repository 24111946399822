import { makeOnRampWithdrawTransaction, makeTransaction } from "api/onRampHooks";
import BaseLayout from "components/shared/BaseLayout";
import { FC, useEffect, useState } from "react";
import PostIntentOpen from "./postIntentOpen";
import PreIntentOpen from "./preIntentOpen";

type UpiIntentContainerProps = {
    init: any
    selectedCurrency: any
    inputAmount: number
    finalAmountYouGet: number
    paymentMethods: any
    bankId: string
    accountNumber: number
    onSuccess: () => void
    external: boolean
    externalNetwork: string
    walletAddress: string
    orderDetails: () => void
    orderId?: string
    setPaymentDetails: (data: any) => void
    cryptoAmount: number
    setCurrentState: (state: any) => void
    transactionId: string
    selectedPaymentApp: string
}

export const UpiIntentContainer: FC<UpiIntentContainerProps> = (props) => {

    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [focussedPostPayment, setFocussedPostPayment] = useState(false)


    // User has switched back to the tab
    const onFocus = () => {
        setFocussedPostPayment(true)
    };

    // User has switched away from the tab (AKA tab is hidden)
    // const onBlur = () => {
    // };

    useEffect(() => {
        window.addEventListener("focus", onFocus);
        // window.addEventListener("blur", onBlur);
        // Calls onFocus when the window first loads
        // onFocus();
        // Specify how to clean up after this effect:
        return () => {
            window.removeEventListener("focus", onFocus);
            // window.removeEventListener("blur", onBlur);
        };
    }, []);

    const bankPaymentMethodObj = props.paymentMethods?.UPI?.options;
    bankPaymentMethodObj[0].name = "gpay_intent";
    const bankPaymentMethod = bankPaymentMethodObj?.length
        ? bankPaymentMethodObj[0]?.name
        : "";

    const handleTransfer = async () => {
        setLoading(true);
        const requestData = props.external
            ? {
                // bank_transaction_id: UTR,
                source_id: props.bankId,
                from_currency: props.selectedCurrency?.fiat_symbol,
                to_currency: "USDT",
                from_amount: props.inputAmount,
                to_amount: props.cryptoAmount,
                payment_method: bankPaymentMethod,
                network: props.externalNetwork,
                crypto_wallet_address: props.walletAddress,
                ...(props.transactionId && {
                    uuid: props.transactionId,
                }),
            }
            : {
                // bank_transaction_id: UTR,
                source_id: props.bankId,
                fiat_symbol: props.selectedCurrency?.fiat_symbol,
                crypto_symbol: "USDT",
                fiat_amount: props.inputAmount,
                crypto_amount: props.finalAmountYouGet,
                payment_method: bankPaymentMethod,
                ...(props.transactionId && {
                    uuid: props.transactionId,
                }),
            };

        try {
            console.log("Making Transaction")
            const buyTransaction = props.external
                ? await makeOnRampWithdrawTransaction({
                    init: props.init,
                    data: requestData,
                })
                : await makeTransaction({
                    init: props.init,
                    data: requestData,
                });

            // props.setCurrentState(StateEnum.SUCCESS_SCREEN);
            props.setPaymentDetails(buyTransaction.data);
            setLoading(false);
            window?.open(buyTransaction.data?.payment_intent)?.focus();
            return buyTransaction?.data?.id;
        } catch (err) {
            setError("Please try again after some time.");
            setLoading(false);
        }
    };

    useEffect(() => {
        handleTransfer();
    }, []);


    let status = <div></div>


    // console.log("focussedPostPayment=>", focussedPostPayment)
    if (!focussedPostPayment) {
        status = <PreIntentOpen inputAmount={props.inputAmount} selectedPaymentApp={props.selectedPaymentApp} />
    } else {
        status = <PostIntentOpen
            inputAmount={props.inputAmount}
            selectedPaymentApp={props.selectedPaymentApp}
            setCurrentState={props.setCurrentState}
        />
    }


    const body = (
        <div
            style={{
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                display: "flex",
                height: "100%",
            }}
        >
            {status}
        </div>
    )

    return (
        <BaseLayout
            headerVisible={true}
            brandingVisible={false}
            body={body}
            footer={undefined}
            bottomSheet={undefined} />
    )
}
