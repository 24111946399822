import { Typography } from "@mui/material";
import upiMethodGooglePay from "assets/images/gpay.png";
import upiMethodPaytm from "assets/images/paytm.png";
import upiMethodPhonePe from "assets/images/phonepe.png";
import upiMethod from "assets/images/upi-method.png";
import { FC } from "react";

type PreIntentOpenProps = {
    inputAmount: number
    selectedPaymentApp: string
}

const payment_icons = (methodName: string) => {
    switch (methodName) {
        case "GOOGLEPAY":
            return upiMethodGooglePay;
        case "PAYTM":
            return upiMethodPaytm;
        case "PHONEPE":
            return upiMethodPhonePe;
        default:
            return upiMethod;
    }
};

export const PreIntentOpen: FC<PreIntentOpenProps> = (props) => {
    return <div
        style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            height: "100%",
        }}
    >
        <div style={{ textAlign: "center" }}>
            <img
                alt="payment-method"
                style={{
                    height: 35,
                    borderWidth: 1,
                    //   borderColor: colors.Grey.borderGrey,
                    borderRadius: 6,
                }}
                src={payment_icons(props.selectedPaymentApp)}
            />
            <Typography
                sx={{ fontSize: 16 }}
                style={{ fontWeight: 700, paddingTop: 5 }}
            >
                Launching App for Payment
            </Typography>
            <Typography
                sx={{ fontSize: 14 }}
                style={{ fontWeight: 400, paddingTop: 3, paddingBottom: 3 }}
            >
                {`Make payment of ${props.inputAmount}`}
            </Typography>
            <Typography
                sx={{ fontSize: 13 }}
                style={{
                    fontWeight: 400,
                    paddingTop: 2,
                    // color: colors.Grey.title,
                }}
            >
                2nd Subtitle
            </Typography>
        </div>
    </div>
}

export default PreIntentOpen