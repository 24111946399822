import React, { useState } from "react";
import {
  setUpConfigAndStartFaceCapture,
  startUserSession,
} from "utils/hyperverge";
import LiveImage from "components/kyc/LiveImage";
import { uploadLiveImage } from "api/kycHooks";
import { isEmpty, base64ToBlob } from "utils/validators";

const LiveImageContainer = ({ onStepCompleted, init, step }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const onContinueClick = async () => {
    try {
      let config = await init();
      startUserSession(config?.user_id);
      setUpConfigAndStartFaceCapture(faceCaptureClosure);
    } catch (err) {}
  };

  const faceCaptureClosure = (error, result) => {
    if (!isEmpty(error)) {
      // Handle error
    } else {
      // Handle result
      const { imgBase64, croppedFace } = result;
      const blob = base64ToBlob(imgBase64 || croppedFace);
      handleLiveImage(blob);
    }
  };

  const handleLiveImage = async (blob) => {
    setIsLoading(true);
    const data = new FormData();
    data.append("live_image", blob);
    try {
      const formDataObj = {};
      data.forEach((value, key) => (formDataObj[key] = value));
      await uploadLiveImage(data, init);

      onStepCompleted();
    } catch (err) {
      setIsLoading(false);
      setError(err?.response?.data?.errors?.[0]?.text);
    }
  };

  return (
    <LiveImage
      onContinueClick={onContinueClick}
      isLoading={isLoading}
      error={error}
      step={step}
    />
  );
};

export default LiveImageContainer;
