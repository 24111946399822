import "./App.css";
import Kyc from "./routes/kyc";
import OffRamp from "./routes/offramp";
import OnRamp from "./routes/onramp";
import Success from "./routes/success";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/kyc" element={<Kyc />} />
          <Route path="/offramp" element={<OffRamp />} />
          <Route path="/onramp" element={<OnRamp />} />
          <Route path="/success" element={<Success />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
