import React from "react";
import { Button, CircularProgress, Typography } from "@mui/material";
import colors from "../../../styles/colors";
import { useThemeHook } from "styles/themeMudrex";
import ReactLoading from "react-loading";
const MButton = ({
  text,
  onPress,
  disabled = false,
  loading = false,
  outlineButton,
}) => {
  const [theme] = useThemeHook();
  return (
    <Button
      sx={{
        height: "3rem",
        width: "100%",
        fontWeight: 600,
        lineHeight: "150%",
        textTransform: "none !important",
        ...(disabled && !loading ? theme.buttonDisabled : theme.buttonPrimary),
      }}
      variant={outlineButton ? "outlined" : "contained"}
      disabled={disabled}
      onClick={onPress}
      disableRipple
      disableElevation
      color="secondary"
    >
      <>
        {loading ? (
          <ReactLoading type={"bubbles"} color="#fff" delay={200} />
        ) : (
          <Typography style={{ ...theme.buttonPrimaryText }}>{text}</Typography>
        )}
      </>
    </Button>
  );
};

export default MButton;
