const StateEnum = {
  LOADING: "LOADING",
  ADD_FUNDS: "ADD_FUNDS",
  PAYMENT_METHODS: "PAYMENT_METHODS",
  BANK_PAYMENT_METHOD: "BANK_PAYMENT_METHOD",
  UPI_PAYMENT_METHOD: "UPI_PAYMENT_METHOD",
  INTENT_PAYMENT_METHOD: "INTENT_PAYMENT_METDOD",
  SUCCESS_SCREEN: "SUCCESS_SCREEN",
  WALLET_ADDRESS: "WALLET_ADDRESS",
};

export default StateEnum;
