import React from "react";
import { Alert, Typography } from "@mui/material";
import MBottomSheet from "components/shared/BottomSheet";
import MButton from "components/shared/MButton";
import KycTextInput from "components/shared/KycTextInput";
import styles from "./styles";
import AadharIcon from "assets/svgs/aadhar-icon.svg";
import AadharOtp from "components/kyc/AadharOtp";
import ConfirmAadhar from "components/kyc/ConfirmAadhar";
import CaptureAadhar from "components/kyc/CaptureAadhar";
import MLink from "components/shared/Link";
import MHeading from "components/shared/MHeading";
import BaseLayout from "components/shared/BaseLayout";
import LoadingScreen from "components/shared/LoadingScreen";
const AadharDetails = ({
  onContinueClick,
  showBottomSheet,
  closeBottomSheet,
  aadhar,
  setAadhar,
  captcha,
  setCaptcha,
  onConfirmClick,
  bottomSheetKey,
  captchaImage,
  startAadharCapture,
  onSubmitOtpClick,
  refetchCaptcha,
  onEditAadharNumber,
  aadharDetails,
  onResendCodeClick,
  isLoading,
  otpErrorText,
  resetOtpErrorText,
  isScreenLoading,
  isCaptchaLoading,
  showOtpSent,
  captchaErrorText,
  aadharImageError,
  aadharErrors,
  setAadharErrors,
  setShowOtpSent,
  onReenterAadhar,
  ocrEnabled,
  step,
}) => {
  const renderBottomSheet = () => {
    switch (bottomSheetKey) {
      case "aadhar_otp":
        return (
          <AadharOtp
            onSubmitOtpClick={onSubmitOtpClick}
            onReenterAadhar={onReenterAadhar}
            onResendCodeClick={onResendCodeClick}
            isLoading={showBottomSheet && isLoading}
            otpErrorText={otpErrorText}
            resetOtpErrorText={resetOtpErrorText}
            showOtpSent={showOtpSent}
            setShowOtpSent={setShowOtpSent}
          />
        );
      case "aadhar_confirm":
        return (
          <ConfirmAadhar
            aadharDetails={aadharDetails}
            onEditAadharNumber={onEditAadharNumber}
            onConfirmClick={onConfirmClick}
          />
        );
      case "aadhar_capture":
        return (
          <CaptureAadhar
            aadharImageError={aadharImageError}
            startAadharCapture={startAadharCapture}
            isLoading={showBottomSheet && isLoading}
          />
        );
      default:
        return <div></div>;
    }
  };

  const titles = {
    aadhar_otp: "Aadhaar OTP",
    aadhar_confirm: "Confirm Aadhaar",
    aadhar_capture: "Capture Aadhaar",
  };

  const shouldDisableButton = aadhar?.length !== 12 || captcha?.length === 0;
  const shouldButtonLoad = !showBottomSheet && isLoading;
  const classes = styles();

  return (
    <>
      {isScreenLoading ? (
        <LoadingScreen />
      ) : (
        <BaseLayout
          steps={step}
          theme={"primary"}
          headerTitle={"Verify Account"}
          headerVisible={true}
          body={
            <div id="body">
              <MHeading heading="Enter your aadhaar" />
              <KycTextInput
                placeholder={"8989 8989 8988"}
                text={aadhar}
                setText={(text) => {
                  setAadhar(text);
                  setAadharErrors("");
                }}
                keyboardType={"number"}
                maxLength={12}
                trailing={
                  <img
                    alt="Aadhaar icon"
                    style={{ height: "1.5rem" }}
                    src={AadharIcon}
                  />
                }
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {isCaptchaLoading ? null : (
                  <div className={classes.captchaContainer}>
                    {captchaImage && (
                      <img
                        alt="Aadhaar Captcha"
                        src={`data:image/png;base64,${captchaImage}`}
                        className={classes.captcha}
                      />
                    )}
                  </div>
                )}

                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    lineHeight: "1em",
                    height: "1em",
                    padding: "20px",
                  }}
                >
                  <MLink
                    text="Refresh"
                    onClick={refetchCaptcha}
                    loading={isCaptchaLoading}
                    disabled={isCaptchaLoading}
                  />
                </div>
              </div>
              <KycTextInput
                placeholder={"Type the characters you see above"}
                text={captcha}
                setText={(text) => {
                  setCaptcha(text);
                  setAadharErrors("");
                }}
                autoCapitalize={false}
                errorText={captchaErrorText}
              />
              {aadharErrors ? (
                <div style={{ marginBottom: 10 }}>
                  <Alert severity="error">
                    <Typography variant="alert">{aadharErrors}</Typography>
                  </Alert>
                </div>
              ) : null}
              {ocrEnabled && (
                <MLink
                  text="Aadhaar not linked with phone?"
                  onClick={startAadharCapture}
                />
              )}
            </div>
          }
          footer={
            <MButton
              onPress={onContinueClick}
              disabled={shouldDisableButton}
              text={"Continue"}
              size="medium"
              loading={shouldButtonLoad}
            />
          }
          bottomSheet={
            <MBottomSheet
              isVisible={showBottomSheet}
              close={closeBottomSheet}
              containsTextInput={bottomSheetKey === "aadhar_otp"}
              title={titles[bottomSheetKey]}
            >
              {renderBottomSheet()}
            </MBottomSheet>
          }
        />
      )}
    </>
  );
};

export default AadharDetails;
