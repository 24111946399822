import MenuIcon from "@mui/icons-material/Menu";
import { Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import BackButton from "assets/svgs/back-arrow.svg";
import ExpiryBottomsheet from "components/onRamp/ExpiryBottomsheet";
import { ConfigContext } from "context/ConfigContext";
import { useToast } from "hooks/useToast";
import React, { useContext, useRef, useState } from "react";
import { successThemeMap, useThemeHook } from "styles/themeMudrex";
import Shield from "../../../assets/svgs/shield.svg";
import colors from "../../../styles/colors";
import MBottomSheet from "../BottomSheet";
import CountdownTimer from "../CountdownTimer";
import { InsideDrawer } from "../Drawer/InsideDrawer";
import MRow from "../MRow";
import MToast from "../Toast";
import { SideNav } from "../side-nav";
import styles from "./styles";
import MStepper from "../MStepper";
const BaseLayout = ({
  body,
  footer,
  bottomSheet,
  headerTitle = "",
  headerVisible = false,
  brandingVisible = true,
  steps = null,
}) => {
  const toast = useToast();
  const [theme] = useThemeHook();
  const configContext = useContext(ConfigContext);
  const expiresAt = configContext?.expiresAt;
  const defaultWidth = 414;
  const defaultHeight = 750;
  var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  var Android = /Android/.test(navigator.userAgent);

  const [height, setHeight] = React.useState(window.innerHeight);
  const [width, setWidth] = React.useState(window.innerWidth);
  React.useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    });
  }, []);

  const rootRef = useRef(null);

  const [isExpiryBottomsheetVisible, setIsExpiryBottomsheetVisibile] =
    useState(false);

  const onExpiry = () => {
    setIsExpiryBottomsheetVisibile(true);
  };

  const [showSidebar, setShowSidebar] = useState(false);
  const toggleSidebar = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setShowSidebar(open);
  };

  const onBack = () => {
    window.parent.postMessage(
      {
        event: "back",
      },
      "*"
    );
  };

  const classes = styles();
  return (
    <>
      <div
        style={{
          placeItems: "center",
          justifyContent: "center",
          display: "grid",
          margin: "auto",
          overflow: "hidden",
          position: "relative",
          background: "#FFFFF",
        }}
      >
        <div className={classes.mainContainer}>
          <div
            className={classes.mainBody}
            style={{
              height:
                iOS || Android
                  ? window.innerHeight
                  : height > defaultHeight
                  ? "600px"
                  : height,
              width:
                iOS || Android
                  ? window.innerWidth
                  : width > defaultWidth
                  ? "400px"
                  : width,
            }}
          >
            {toast.toasts.map((toast) => (
              <MToast toast={toast} />
            ))}
            <div className={classes.childPadding}>
              {headerVisible && (
                <>
                  <Stack
                    direction={"row"}
                    id="header"
                    style={{
                      padding: 20,
                      height: 70,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 15,
                      ...theme.headerBackground,
                    }}
                  >
                    <img src={BackButton} onClick={onBack} />
                    <Typography
                      style={{
                        flexGrow: 1,
                        fontSize: "16px",
                        fontWeight: 600,
                        ...theme.gradientText,
                      }}
                    >
                      {headerTitle}
                    </Typography>
                    {configContext?.config?.features?.side_nav?.enabled && (
                      <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleSidebar(true)}
                        edge="start"
                        sx={{ mr: 2, ...(showSidebar && { display: "none" }) }}
                      >
                        <MenuIcon sx={{ color: theme.gradientText.color }} />
                      </IconButton>
                    )}
                  </Stack>
                  {steps && (
                    <MStepper
                      totalSteps={steps.totalSteps}
                      currentStep={steps.currentStep}
                    />
                  )}
                  {expiresAt && (
                    <div
                      style={{
                        borderRadius: 0,
                        marginTop: 0,
                        padding: "10px 20px 10px 20px",
                        ...theme.bannerBackground,
                        justifyContent: "center",
                        alignItems: "baseline",
                      }}
                    >
                      <MRow
                        leftElement={
                          <Typography fontSize={14}>
                            {`${configContext?.config?.messages?.expiry?.title}`}
                          </Typography>
                        }
                        rightElement={
                          <CountdownTimer
                            eventTime={expiresAt}
                            onExpiry={onExpiry}
                          />
                        }
                      />
                    </div>
                  )}
                </>
              )}
              <div
                style={{
                  padding: "20px",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  overflow: "hidden",
                  ...successThemeMap[
                    configContext.themeState.currentState.theme
                  ],
                }}
              >
                <div className={classes.container}>{body}</div>
                {footer}
                {configContext?.config?.branding && brandingVisible && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: 10,
                      flexDirection: "row",
                      alignItems: "center",
                      color: colors.Grey.title,
                    }}
                  >
                    <Typography
                      variant="alert"
                      fontSize={10}
                      style={{ marginRight: 5, color: colors.Dark.title }}
                    >
                      {configContext?.config?.branding}
                    </Typography>
                    <img src={Shield} />
                  </div>
                )}
              </div>
              {isExpiryBottomsheetVisible && (
                <MBottomSheet isVisible={isExpiryBottomsheetVisible}>
                  <ExpiryBottomsheet />
                </MBottomSheet>
              )}
              {bottomSheet}
            </div>
            {configContext?.config?.features?.side_nav?.enabled && (
              <InsideDrawer
                sx={{ width: 256 }}
                anchor={"right"}
                open={showSidebar}
                onClose={toggleSidebar(false)}
              >
                <Box
                  onClick={toggleSidebar(false)}
                  onKeyDown={toggleSidebar(false)}
                >
                  <SideNav />
                </Box>
              </InsideDrawer>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BaseLayout;
