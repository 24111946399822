import { useEffect, useState } from "react";
import networkCallWrapper from "./networkCallWrapper";

const useNetworkRequest = (
  networkCallParams,
  dependencyList = [],
  dependencyCondition = true,
  forceUpdate = false
) => {
  const [networkState, setNetworkState] = useState({
    isFetching: true,
    isRevalidating: false,
    data: null,
    errors: null,
    refreshData: false,
  });

  const refresh = () => {
    setNetworkState((prev) => ({
      ...prev,
      refreshData: !prev.refreshData,
      isRevalidating: true,
    }));
  };

  useEffect(() => {
    const func = async () => {
      setNetworkState((prev) => ({
        ...prev,
        isFetching: prev.isRevalidating ? false : true,
      }));
      const url = networkCallParams?.url;
      const method = networkCallParams?.method;
      const service = networkCallParams?.service;
      const params = networkCallParams?.params;
      const data = networkCallParams?.data;

      const request = await networkCallWrapper(
        url,
        method,
        service,
        params,
        data
      );

      if (request.success) {
        setNetworkState((prev) => ({
          ...prev,
          data: request?.data,
          isFetching: false,
          isRevalidating: false,
        }));
      } else {
        setNetworkState((prev) => ({
          ...prev,
          errors: request?.errors,
          isFetching: false,
          isRevalidating: false,
        }));
      }
    };

    if (networkState.data && !networkState?.isRevalidating && !forceUpdate) {
      // do nothing
    } else {
      if (dependencyCondition) {
        func();
      }
    }
  }, [networkState?.refreshData, ...dependencyList, dependencyCondition]);

  return {
    refresh: refresh,
    errors: networkState.errors,
    isFetching: networkState.isFetching,
    data: networkState.data,
    isRevalidating: networkState.isRevalidating,
  };
};

export default useNetworkRequest;
