import React, { useState } from "react";
import styles from "./styles";
import CopyToClipboard from "assets/svgs/copytoclipboard.svg";
import MButton from "components/shared/MButton";
import KycTextInput from "components/shared/KycTextInput";
import { IconButton, Typography, Alert, Button } from "@mui/material";
import OffRampSuccess from "components/offRamp/OffRampSuccess";
import MHeading from "components/shared/MHeading";
import BaseLayout from "components/shared/BaseLayout";
import MLink from "components/shared/Link";
import { useThemeHook } from "styles/themeMudrex";
import colors from "styles/colors";
import MRow from "components/shared/MRow";
import { useToast } from "hooks/useToast";
import { EventLevel, sendEvent } from "utils/events/handler";
const BankTransferMethod = ({
  amount,
  handleCopy,
  handleTransfer,
  accountDetails,
  UTR,
  loading,
  error,
  setUTR,
  setError,
  bankPaymentMethod,
  selectedCurrency,
  accountNumber,
}) => {
  const classes = styles();
  const toast = useToast();
  const [nameCopied, setNameCopied] = useState(false);
  const [numberCopied, setNumberCopied] = useState(false);
  const [codeCopied, setCodeCopied] = useState(false);
  const [theme] = useThemeHook();
  const handleUTR = (value) => {
    const numericRegex = /^([0-9]{1,100})+$/;
    const alphaNumericRegex = /^\w+$/;
    const checkMethod =
      bankPaymentMethod !== "sepa_transfer" ? numericRegex : alphaNumericRegex;
    if (checkMethod.test(value) || value === "") {
      setUTR(value);
    }
    setError(null);
  };

  return (
    <BaseLayout
      theme={"primary"}
      headerTitle={"IMPS Bank Transfer"}
      headerVisible={true}
      body={
        <div>
          <MHeading
            heading={`Transfer ${selectedCurrency?.currency_symbol}${amount} to this bank account`}
          />
          <div id="top-container" style={styles.topContainer}>
            <div
              style={{
                position: "relative",
              }}
            >
              <MRow
                leftElement={
                  <Typography
                    variant="subtitle1"
                    fontSize={14}
                    style={{ color: colors.Grey.title }}
                  >
                    {`Send Amount from this Account : ${accountNumber}`}
                  </Typography>
                }
              />
              <div style={theme.gradientBackgroundGrey}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderBottomWidth: 1,
                    borderColor: colors.Grey.borderGrey,
                    paddingBottom: 16,
                  }}
                >
                  <div>
                    <Typography
                      variant="caption"
                      style={{ color: colors.Grey.title }}
                    >
                      {"Account Holder Name"}
                    </Typography>
                    <Typography
                      fontSize={14}
                      fontWeight={600}
                      style={{ marginTop: 5 }}
                    >
                      {accountDetails?.account_name}
                    </Typography>
                  </div>
                  <Button
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    sx={{
                      textTransform: "none !important",
                    }}
                    onClick={() => {
                      toast.success(
                        "Account name Copied. Enter it in the app during payment."
                      );
                      setNameCopied(true);
                      handleCopy("bank_name");
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: 4,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img src={CopyToClipboard} />
                      <MLink
                        bold={false}
                        text={nameCopied ? "Copied!" : "Copy"}
                      />
                    </div>
                  </Button>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingTop: 16,
                    borderBottomWidth: 1,
                    borderColor: colors.Grey.borderGrey,
                    paddingBottom: 16,
                  }}
                >
                  <div>
                    <Typography
                      variant="caption"
                      style={{ color: colors.Grey.title }}
                    >
                      {"Account Number"}
                    </Typography>
                    <Typography
                      fontSize={14}
                      fontWeight={600}
                      style={{ marginTop: 5 }}
                    >
                      {accountDetails?.account_number}
                    </Typography>
                  </div>
                  <Button
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    sx={{
                      textTransform: "none !important",
                    }}
                    onClick={() => {
                      toast.success(
                        "Account number Copied. Enter it in the app during payment."
                      );
                      setNumberCopied(true);
                      handleCopy("account_number");
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: 4,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img src={CopyToClipboard} />
                      <MLink
                        bold={false}
                        text={numberCopied ? "Copied!" : "Copy"}
                      />
                    </div>
                  </Button>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingTop: 16,
                    borderBottomWidth: 1,
                    borderColor: colors.Grey.borderGrey,
                    paddingBottom: 16,
                  }}
                >
                  <div>
                    <Typography
                      variant="caption"
                      style={{ color: colors.Grey.title }}
                    >
                      {"IFSC Code"}
                    </Typography>
                    <Typography
                      fontSize={14}
                      fontWeight={600}
                      style={{ marginTop: 5 }}
                    >
                      {accountDetails?.ifsc_code}
                    </Typography>
                  </div>
                  <Button
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    sx={{
                      textTransform: "none !important",
                    }}
                    onClick={() => {
                      toast.success(
                        "IFSC code Copied. Enter it in the app during payment."
                      );
                      setCodeCopied(true);
                      handleCopy("ifsc_code");
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: 4,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img src={CopyToClipboard} />
                      <MLink
                        bold={false}
                        text={codeCopied ? "Copied!" : "Copy"}
                      />
                    </div>
                  </Button>
                </div>
              </div>

              {bankPaymentMethod === "sepa_transfer" ? (
                <div style={styles.sepaNoteContainer}>
                  <Typography style={styles.sepaNote}>
                    {
                      "Once the transfer is complete, enter your IBAN/Bank account number below which you used to transfer funds."
                    }
                  </Typography>
                </div>
              ) : null}

              <div style={{ marginTop: 20 }}>
                <MRow
                  leftElement={
                    <Typography variant="caption" style={{ fontSize: 14 }}>
                      <strong>{"Enter UTR"}</strong>
                    </Typography>
                  }
                  rightElement={
                    <MLink
                      direction="flex-end"
                      text={"How to get UTR?"}
                      // open in new tab in browser
                      onClick={() => {
                        window
                          .open(
                            "https://support.mudrex.com/hc/en-us/articles/5702456841497"
                          )
                          .focus();
                      }}
                    />
                  }
                />
              </div>
              <KycTextInput
                placeholder={"Ex: 488939434989"}
                text={UTR}
                setText={handleUTR}
                style={styles.UTRInput}
              />
            </div>
            {error && (
              <div style={{ marginBottom: 10 }}>
                <Alert severity="error">
                  <Typography variant="alert">{error}</Typography>
                </Alert>
              </div>
            )}
          </div>
        </div>
      }
      footer={
        <div className={classes.transferredButtonContainer}>
          <MButton
            onPress={() => {
              sendEvent("transfer_claim", EventLevel.INFO, {}, {})
              handleTransfer()
            }}
            text={"I have transferred"}
            isPurpleButton
            size="medium"
            loading={loading}
            disabled={loading}
          />
        </div>
      }
    />
  );
};

export default BankTransferMethod;
