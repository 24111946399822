import { ThemeProvider, createTheme } from "@mui/material/styles";
import { getConfig } from "api/kycHooks";
import LoadingScreen from "components/shared/LoadingScreen";
import { ConfigContext } from "context/ConfigContext";
import { NavContext, sdkSteps } from "context/NavContext";
import { ToastContextProvider } from "context/ToastContext";
import { UserContextProvider } from "context/UserContext";
import ExceptionContainer from "pages/Exception";
import KycDetails from "pages/kyc";
import OffRamp from "pages/offRamp";
import OnRamp from "pages/onRamp";
import { TransactionList } from "pages/profile/transactions";
import { useEffect, useState } from "react";
import themeOptions from "styles/theme";

const Caas = ({
  init,
  sdkConfiguration,
  onKYCSuccessCallback,
  onRampSuccessCallback,
  offRampSuccessCallback,
  onExceptionCallback,
  exceptionCTA,
  fiatAmount,
  startCustomKYCCallback,
  onBack,
  withdrawAddress,
  transactionId,
  orderId,
  withdrawNetwork,
  cryptoAmount,
  cryptoSymbol,
  paymentMethod,
  expiresAt,
}) => {
  const [currentState, setCurrentState] = useState({
    theme: "primary",
    header: {
      title: "",
      visible: true,
    },
  });

  const [currentStep, setCurrentStep] = useState(7);
  const [config, setConfig] = useState({});
  const [item, setItem] = useState(null);
  const kycSuccessCallbackMap = {
    kyc: () => {
      onKYCSuccessCallback();
    },
    onramp: () => {
      setCurrentStep(2);
    },
    offramp: () => {
      setCurrentStep(3);
    },
    loading: () => {
      setCurrentStep(0);
    },
  };

  const startKYC = () => {
    sdkConfiguration?.kycEnabled ? setCurrentStep(1) : startCustomKYCCallback();
  };

  const onRetry = () => {
    window.location.reload();
  };

  const initializeSDK = async () => {
    try {
      const result = await getConfig(init);
      // result.data.theme = null;
      setConfig(result?.data);
    } catch (e) { }
  };

  useEffect(() => {
    initializeSDK();
    window.addEventListener("message", (event) => {
      let receivedData;
      try {
        receivedData = event.data;
        setItem(receivedData.item);
      } catch (error) {
        console.error("Error parsing received JSON:", error);
        return;
      }
    });
  }, []);

  useEffect(() => {
    if (config) {
      let currentStep = sdkConfiguration?.steps?.[0];
      setCurrentStep(sdkSteps[currentStep] || 4);
    }
  }, [config]);

  return (
    <ConfigContext.Provider
      value={{
        init: init,
        config: config,
        ...(expiresAt && { expiresAt: expiresAt }),
        themeState: {
          currentState,
          setCurrentState,
        },
      }}
    >
      <ToastContextProvider>
        {config ? (
          <ThemeProvider
            theme={config?.theme ? createTheme(config?.theme) : themeOptions}
          >
            <NavContext.Provider value={{ currentStep, setCurrentStep }}>
              <UserContextProvider init={init}>
                {currentStep === 1 ? (
                  <KycDetails
                    init={init}
                    onSuccess={
                      kycSuccessCallbackMap?.[sdkConfiguration?.steps?.[0]] ||
                      kycSuccessCallbackMap.kyc
                    }
                    onException={onExceptionCallback}
                    exceptionCTA={exceptionCTA}
                    bankEnabled={sdkConfiguration?.bankEnabled}
                    stepsEnabled={sdkConfiguration?.stepsEnabled}
                    ocrEnabled={config?.ocr_enabled}
                  />
                ) : currentStep === 2 ? (
                  <OnRamp
                    init={init}
                    fiatAmount={Math.abs(fiatAmount)}
                    startKYC={startKYC}
                    onBack={onBack}
                    onSuccess={onRampSuccessCallback}
                    external={cryptoAmount || withdrawAddress ? true : false}
                    externalAddress={withdrawAddress}
                    externalNetwork={withdrawNetwork}
                    createdOrderId={orderId}
                    transactionId={transactionId}
                    cryptoAmount={Math.abs(cryptoAmount)}
                    cryptoSymbol={cryptoSymbol}
                    paymentMethod={paymentMethod}
                    item={item}
                  />
                ) : currentStep === 3 ? (
                  <OffRamp
                    init={init}
                    enteredInputAmount={fiatAmount}
                    startKYC={startKYC}
                    onBack={onBack}
                    onSuccess={offRampSuccessCallback}
                  />
                ) : currentStep === 4 ? (
                  <>
                    <ExceptionContainer
                      onRetry={onRetry}
                      showBottomSheet={true}
                    />
                  </>
                ) : currentStep === 6 ? (
                  <TransactionList />
                ) : (
                  currentStep === 7 && (
                    <LoadingScreen headerVisible={false} mudrexLoader={false} />
                  )
                )}
              </UserContextProvider>
            </NavContext.Provider>
          </ThemeProvider>
        ) : (
          <LoadingScreen headerVisible={false} mudrexLoader={false} />
        )}
      </ToastContextProvider>
    </ConfigContext.Provider>
  );
};

export default Caas;
