import { makeOnRampWithdrawTransaction, makeTransaction, updateOnRampWithdrawTransaction } from "api/onRampHooks";
import ChoosePaymentsMethod from "components/onRamp/ChoosePaymentsMethod";
import { useEffect, useState } from "react";
import StateEnum from "../states";

const ChoosePaymentsMethodContainer = ({
  init,
  selectedCurrency,
  paymentMethods,
  setCurrentState,
  fiatAmount,
  bankId,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  setSelectedPaymentApp,
  isKycPending,
  startKYC,
  external,
  fromAmount,
  toAmount,
  externalNetwork,
  walletAddress,
  transactionId,
  orderId,
  setPaymentDetails
}) => {

  const [bankAccountDetails, setBankAccountDetails] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   setLoading(true);
  //   const fetchBankDetails = async () => {
  //     const accountDetailsRequest = await fetchBankAccountDetails({
  //       init: init,
  //       bankPaymentMethod: selectedPaymentMethod,
  //       fiat_symbol: selectedCurrency?.fiat_symbol,
  //     });

  //     if (accountDetailsRequest.success) {
  //       setBankAccountDetails(accountDetailsRequest.data);
  //     } else {
  //       setError("Please try again after some time.");
  //     }
  //     setLoading(false);
  //   };

  //   try {
  //     fetchBankDetails();
  //   } catch (err) {
  //     setError("Please try again after some time.");
  //     setLoading(false);
  //   }
  // }, [])

  useEffect(() => {
    const handleTransfer = async () => {
      setLoading(true);
      const requestData = external
        ? {
          verification_code: bankAccountDetails?.code,
          source_id: bankId,
          from_currency: selectedCurrency?.fiat_symbol,
          to_currency: "USDT",
          // from_amount: fromAmount,
          to_amount: toAmount,
          payment_method: selectedPaymentMethod,
          network: externalNetwork,
          crypto_wallet_address: walletAddress,
          ...(transactionId && {
            uuid: transactionId,
          }),
        }
        : {
          verification_code: bankAccountDetails?.code,
          source_id: bankId,
          fiat_symbol: selectedCurrency?.fiat_symbol,
          crypto_symbol: "USDT",
          // fiat_amount: fromAmount,
          crypto_amount: toAmount,
          payment_method: selectedPaymentMethod,
          ...(transactionId && {
            uuid: transactionId,
          }),
        };

      console.log("handleTransfer ~ requestData=>", requestData)


      try {
        const buyTransaction = orderId
          ? await updateOnRampWithdrawTransaction({
            init,
            orderId: orderId,
            verification_code: bankAccountDetails?.code,
          })
          : external
            ? await makeOnRampWithdrawTransaction({
              init: init,
              data: requestData,
            })
            : await makeTransaction({
              init: init,
              data: requestData,
            });


        setPaymentDetails(buyTransaction.data);
        setLoading(false);

        let newState
        if (selectedPaymentMethod == "upi_transfer") {
          newState = StateEnum.UPI_PAYMENT_METHOD
        } else {
          newState = StateEnum.BANK_PAYMENT_METHOD
        }

        setCurrentState(newState)

        return buyTransaction?.data?.id;
      } catch (err) {
        setError("Please try again after some time.");
        setLoading(false);
      }
    };


    console.log("useEffect ~ selectedPaymentMethod=>", selectedPaymentMethod)
    if (external && selectedPaymentMethod != "") {
      handleTransfer()
    }
  }, [selectedPaymentMethod])

  //pre-choosepaymentmethods action
  const checkForDefaultPayementMethod = (methods) => {
    let enabledMethods = [];
    for (let method in methods) {
      if (methods[method].enabled) {
        enabledMethods.push(method);
      }
    }
    if (enabledMethods.length === 1) {
      if (enabledMethods[0] === "BANK_TRANSFER") {
        setCurrentState(StateEnum.BANK_PAYMENT_METHOD);
      } else if (enabledMethods[1] === "UPI") {
        setCurrentState(StateEnum.INTENT_PAYMENT_METHOD);
      }
    }
  };

  const onClickMap = (methodName) => {
    console.log("onClickMap ~ methodName=>", methodName)
    if (isKycPending) {
      return startKYC();
    }

    if (methodName.includes("gpay")) {
      setSelectedPaymentApp("GOOGLEPAY");
    } else if (methodName.includes("paytm")) {
      setSelectedPaymentApp("PAYTM");
    } else if (methodName.includes("phonepe")) {
      setSelectedPaymentApp("PHONEPE");
    }

    if (methodName.includes("intent")) {
      setSelectedPaymentMethod("upi_intent");
      return setCurrentState(StateEnum.INTENT_PAYMENT_METHOD);
    } else if (methodName.includes("transfer")) {
      setSelectedPaymentMethod("upi_transfer");
      return setCurrentState(StateEnum.UPI_PAYMENT_METHOD);
    } else if (methodName == "bank_transfer") {
      setSelectedPaymentMethod("bank_transfer");
      return setCurrentState(StateEnum.BANK_PAYMENT_METHOD);
    }
  };

  useEffect(() => {
    checkForDefaultPayementMethod(paymentMethods);
  }, []);

  return (
    <ChoosePaymentsMethod
      methods={paymentMethods}
      isLoadingMethods={paymentMethods === null}
      setCurrentState={setCurrentState}
      fiatAmount={fiatAmount}
      onClickMap={onClickMap}
      isKycPending={isKycPending}
      loading={loading}
      error={error}
    />
  )
};

export default ChoosePaymentsMethodContainer;
