import { ThemeProvider } from "@mui/material/styles";
import Caas from "pages/caas";
import { useSearchParams } from "react-router-dom";
import themeOptions from "styles/theme";

const OffRamp = ({ }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const init = async () => {
    return {
      signature: searchParams.get("token"),
      timestamp: searchParams.get("ts"),
      userId: searchParams.get("user_id"),
      clientId: searchParams.get("client_id"),
      mode: searchParams.get("mode"),
      hv_token: searchParams.get("hv_token"),
    };
  };

  return (
    <>
      <ThemeProvider theme={themeOptions}>
        <Caas
          init={init}
          sdkConfiguration={{
            steps: ["offramp"],
            kycEnabled: true,
            bankEnabled: true,
          }}
          onKYCSuccessCallback={() => { }}
          onRampSuccessCallback={() => { }}
          offRampSuccessCallback={() => { }}
          onExceptionCallback={() => {
            window.location.reload();
          }}
          exceptionCTA={"Try again"}
          startCustomKYCCallback={() => { }}
          onBack={() => { }}
        />
      </ThemeProvider>
    </>
  );
};

export default OffRamp;
