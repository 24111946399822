import axios from "../utils/axiosInstance";
import { getSignature } from "../utils/signatureUtils";

const headers = {
  "Content-Type": "multipart/form-data",
};

export const getNameFromPAN = async (data, init) => {
  const result = await axios({
    url: "user_kyc/pan/extract_name",
    method: "POST",
    service: "user",
    data,
    init,
  });

  if (result?.data?.success) {
    return result.data?.data;
  }
  return Promise.reject("No data");
};

export const updateUserConsentForPan = async (data, init) => {
  const result = await axios({
    url: "user_kyc/pan/user_consent",
    method: "POST",
    service: "user",
    data,
    init,
  });
  if (result?.data?.success) {
    return result.data?.data;
  }
  return Promise.reject("No data");
};

export const getCaptcha = async (init) => {
  const result = await axios({
    url: "user_kyc/aadhar/get_captcha",
    method: "GET",
    service: "user",
    init,
  });
  if (result?.data?.success) {
    return result.data?.data;
  }
  return Promise.reject("No data");
};

export const verifyCaptchaAndGetOTP = async (data, init) => {
  const result = await axios({
    url: "user_kyc/aadhar/verify_captcha_for_otp",
    method: "POST",
    service: "user",
    data,
    init,
  });

  if (result?.data?.success) {
    return result.data?.data;
  }
  return Promise.reject("No data");
};

export const verifyAadharOtp = async (data, init) => {
  const result = await axios({
    url: "user_kyc/aadhar/verify_otp_for_verification",
    method: "POST",
    service: "user",
    data,
    init,
  });

  if (result?.data?.success) {
    return result.data?.data;
  }
  return Promise.reject("No data");
};

export const uploadOCRData = async (data, init) => {
  const result = await axios({
    url: "user_kyc/aadhar/ocr_details",
    method: "POST",
    service: "user",
    data,
    init,
    headers,
  });

  if (result?.data?.success) {
    return result.data?.data;
  }
  return Promise.reject("No data");
};

export const uploadLiveImage = async (data, init) => {
  const result = await axios({
    url: "user_kyc/liveliness/live_image",
    method: "POST",
    service: "user",
    data,
    init,
    headers,
  });

  if (result?.data?.success) {
    return result.data?.data;
  }
  return Promise.reject("No data");
};

export const addAccount = async (data, init) => {
  const result = await axios({
    url: "user_kyc/bank_account",
    method: "POST",
    service: "user",
    data: data,
    init: init,
  });
  if (result?.data?.success) {
    return result.data?.data;
  }
  return Promise.reject("No data");
};

export const getAccountStatus = async (init) => {
  const result = await axios({
    url: "user_kyc/bank_status",
    method: "GET",
    service: "user",
    init: init,
  });

  if (result?.data?.success) {
    return result.data?.data;
  }
  return Promise.reject("No data");
};

export const deleteAccount = async (data, init) => {
  const result = await axios({
    url: "user_kyc/bank_account",
    method: "POST",
    service: "user",
    data,
    init: init,
  });

  if (result?.data?.success) {
    return result.data?.data;
  }
  return Promise.reject("No data");
};

export const getUserKyc = async (init) => {
  const result = await axios({
    url: "kyc/for_sdk",
    method: "GET",
    service: "userv2",
    params: {
      source: "APP",
    },
    init,
  });
  if (result?.data?.success) {
    return result.data?.data;
  }
  return Promise.reject("No data");
};

export const getUser = async (init) => {
  const signature = await getSignature(init);

  const result = await axios({
    url: `client_user?user_uuid=${signature?.userId}`,
    method: "GET",
    service: "userCaas",
    init: init,
  });

  if (result?.data?.success) {
    return result.data?.data;
  }
  return Promise.reject("No data");
};

export const sendPhoneOtp = async (phone, init) => {
  try {
    const data = new FormData();
    data.append("phone", `+91${phone}`);
    const result = await axios({
      url: "client/send_otp",
      method: "POST",
      service: "user",
      data: data,
      headers: headers,
      init: init,
    });

    if (result?.data?.success) {
      return { success: true, error: null };
    } else {
      return {
        success: false,
        error: result?.data?.errors?.[0] || result?.data?.data?.message,
      };
    }
  } catch (e) {
    return {
      success: false,
      error: "Something went wrong, please try again later",
    };
  }
};

export const verifyPhoneOtp = async (phone, otp, init) => {
  try {
    const data = new FormData();
    data.append("phone", `+91${phone}`);
    data.append("otp", otp);
    const result = await axios({
      url: "verify_phone_otp",
      method: "POST",
      service: "user",
      data: data,
      headers: headers,
      init: init,
    });

    if (result?.data?.success) {
      return { success: true, error: null };
    } else {
      return {
        success: false,
        error: result?.data?.errors?.[0] || result?.data?.data?.message,
      };
    }
  } catch (e) {
    return {
      success: false,
      error: "Something went wrong, please try again later",
    };
  }
};

export const sendEmailOtp = async (email, init) => {
  try {
    const data = { email: email };
    const result = await axios({
      url: "send_email_otp",
      method: "POST",
      service: "user",
      data: data,
      init: init,
    });
    if (result?.data?.success) {
      return { success: true, error: null };
    } else {
      return {
        success: false,
        error: result?.data?.errors?.[0] || result?.data?.data?.message,
      };
    }
  } catch (e) {
    return {
      success: false,
      error: "Something went wrong, please try again later",
    };
  }
};

export const verifyEmailOtp = async (email, otp, init) => {
  try {
    const data = { email: email, otp: otp };
    const result = await axios({
      url: "verify_email_otp",
      method: "POST",
      service: "user",
      data: data,
      init: init,
    });
    if (result?.data?.success) {
      return { success: true, error: null };
    } else {
      return {
        success: false,
        error: result?.data?.errors?.[0] || result?.data?.data?.message,
      };
    }
  } catch (e) {
    return {
      success: false,
      error: "Something went wrong, please try again later",
    };
  }
};

export const getConfig = async (init) => {
  try {
    const result = await axios({
      url: "sdk/config",
      method: "GET",
      service: "user",
      init: init,
    });
    if (result?.data?.success) {
      return { success: true, data: result?.data?.data };
    } else {
      return { success: false, data: "" };
    }
  } catch (e) {
    return {
      success: false,
      error: "",
    };
  }
};

export const getHVToken = async (init) => {
  try {
    const result = await axios({
      url: "client_user/get_kyc_token",
      method: "GET",
      service: "userCaas",
      init: init,
    });
    if (result?.data?.success) {
      return { success: true, data: result?.data?.data };
    } else {
      return { success: false, data: "" };
    }
  } catch (e) {
    return {
      success: false,
      error: "",
    };
  }
};
