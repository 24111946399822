import { plainToInstance } from "class-transformer";
import { User } from "types/user";
import axios from "utils/axiosInstance";
import { getSignature } from "utils/signatureUtils";


export const getUser = async (init: any) => {

    const signature = await getSignature(init);

    const requestConfig: any = {
        url: `client_user?user_uuid=${signature?.userId}`,
        method: "GET",
        service: "userCaas",
        init: init,
    }

    const result = await axios(requestConfig);

    if (result?.data?.success) {
        return plainToInstance(User, result.data?.data as Object, { excludeExtraneousValues: true });
    }
    return Promise.reject("No data");
}
