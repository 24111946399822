import { createContext } from "react";

export type TransactionStates =
  | "processing"
  | "delayed"
  | "failed"
  | "completed"
  | "onRampCompleted";
export type PaymentMethod = "bank_transfer" | "upi_transfer";
export type ThemeConfig = {
  border: string;
  palette: {
    mode: string;
    primary: {
      main: string;
      text: string;
    };
    secondary: {
      main: string;
      text: string;
    };
  };
  typography: {
    alert: {
      fontSize: number;
      lineHeight: string;
    };
    b1: {
      fontSize: number;
      fontWeight: number;
      lineHeight: string;
    };
    fontFamily: string;
    lineHeight: string;
  };
};

export type OnrampMessage = {
  title: string;
  subtitle: string;
  time: string;
};

export type Features = {
  side_nav: {
    enabled: boolean;
  };
  success_screen: {
    header: {
      disabled: boolean;
    };
    continue_button: {
      disabled: boolean;
    };
  };
  payment_methods: {
    allow_intent: boolean
  }
};

export type Config = {
  branding: string;
  messages: {
    expiry: {
      title: string;
    };
    onramp: Record<TransactionStates, OnrampMessage>;
    support: {
      title: string;
      url: string;
    };
  };
  ocr_enabled: boolean;
  sla: Record<PaymentMethod, number>;
  theme: ThemeConfig;
  features: Features;
};

const defaultConfig = {
  branding: "Powered by Mudrex",
  messages: {
    expiry: {
      title: "Your reward is reserved at this price.",
    },
    onramp: {
      completed: {
        subtitle: "You have purchased the reward",
        time: "This may take upto 5 min ",
        title: "Payment successful",
      },
      delayed: {
        subtitle: "It is taking longer than expected",
        time: "This may take upto 5 min ",
        title: "Processing your payment",
      },
      failed: {
        subtitle: "We were not able to find your payment",
        time: "This may take upto 5 min ",
        title: "Payment failed",
      },
      onRampCompleted: {
        subtitle: "You purchase is in progress",
        time: "This may take upto 5 min ",
        title: "We have recieved your funds",
      },
      processing: {
        subtitle: "We will let you know once completed",
        time: "This may take upto 5 min ",
        title: "Processing your payment...",
      },
    },
    support: {
      title: "Facing problems, get help",
      url: "https://support.mudrex.com/",
    },
  },
  ocr_enabled: false,
  sla: {
    bank_transfer: 3000,
    upi_transfer: 100,
  },
  theme: {
    border: "0",
    palette: {
      mode: "light",
      primary: {
        main: "#000000",
        text: "#FFFFFF",
      },
      secondary: {
        main: "#000000",
        text: "#FFFFFF",
      },
    },
    typography: {
      alert: {
        fontSize: 10,
        lineHeight: "150%",
      },
      b1: {
        fontSize: 16,
        fontWeight: 400,
        lineHeight: "150%",
      },
      fontFamily: "Inter",
      lineHeight: "150%",
    },
  },
  features: {
    side_nav: {
      enabled: false,
    },
    success_screen: {
      header: {
        disabled: false,
      },
      continue_button: {
        disabled: false,
      },
    },
    payment_methods: {
      allow_intent: false
    }
  },
};

export const ConfigContext = createContext<{ config: Config }>({
  config: defaultConfig,
});
