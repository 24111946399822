import React, { useContext, useState } from "react";
import MButton from "components/shared/MButton";
import MBottomSheet from "components/shared/BottomSheet";
import NoteTabs from "../NoteTabView";
const NoteBottomsheet = ({ isVisible, onClose, selectedPaymentApp }) => {
  return (
    <MBottomSheet
      isVisible={isVisible}
      close={() => {
        onClose();
      }}
      title={"How to Add Note"}
      sticky={false}
    >
      <div>
        <div>
          <NoteTabs selectedPaymentApp={selectedPaymentApp} />
        </div>
        <MButton
          onPress={() => {
            onClose();
          }}
          text={`Got it!`}
        />
      </div>
    </MBottomSheet>
  );
};

export default NoteBottomsheet;
