import networkCallWrapper from "hooks/networking/networkCallWrapper";

export const TriggerScraper = async () => {

    const request = await networkCallWrapper(
        "v1/conversion/fiat/transactions/scraping/trigger",
        "POST",
        "wallet",
        null,
        null
      );

    return request;
};