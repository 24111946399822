import { getUser } from "api/kycHooks";
import {
  amountYouGet,
  amountYouGetExternal,
  conversionBuyMenthods,
  fetchPaymentMethods,
  getOnRampTransaction,
  getOnRampWithdrawTransaction,
} from "api/onRampHooks";
import OffRampSuccess from "components/offRamp/OffRampSuccess";
import LoadingScreen from "components/shared/LoadingScreen";
import { ConfigContext } from "context/ConfigContext";
import AddFunds from "pages/onRamp/AddFunds";
import BankTransferContainer from "pages/onRamp/BankTransferContainer";
import ChoosePaymentsMethodContainer from "pages/onRamp/ChoosePaymentMethods";
import UPITransferContainer from "pages/onRamp/UPITransferContainer";
import WalletScreenContainer from "pages/onRamp/WalletScreenContainer";
import { useContext, useEffect, useRef, useState } from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { UpiIntentContainer } from "./UpiIntent/container";
import StateEnum from "./states";
import "./styles.css";
const OnRamp = ({
  onSuccess,
  init,
  fiatAmount,
  startKYC,
  external,
  externalAddress = null,
  externalNetwork = null,
  createdOrderId = "",
  cryptoAmount,
  cryptoSymbol,
  paymentMethod,
  transactionId,
  item,
}) => {
  const configContext = useContext(ConfigContext);
  const [selectedPaymentApp, setSelectedPaymentApp] = useState(null);
  const [currentState, setCurrentState] = useState("LOADING");
  const [walletAddress, setWalletAddress] = useState(externalAddress);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [selectedCryptoCurrency, setSelectedCryptoCurrency] = useState(null);
  const [inputAmount, setInputAmount] = useState(fiatAmount);
  const [conversionDetails, setConversionDetails] = useState(null);
  const [areConversionDetailsLoading, setAreConversionDetailsLoading] =
    useState(true);
  const [currenciesList, setCurrenciesList] = useState(null);
  const [checkingLimits, setCheckingLimits] = useState(false);
  const [showCrashError, setShowCrashError] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState(null);
  const [isKycPending, setIsKycPending] = useState(false);
  const [bankId, setBankId] = useState(null);
  const [accountNumber, setAccountNumber] = useState(null);
  const [orderId, setOrderId] = useState(createdOrderId);
  const [orderDetails, setOrderDetails] = useState(null);
  const [inputCryptoAmount, setInputCryptoAmount] = useState(
    cryptoSymbol && !cryptoAmount && !fiatAmount ? 10 : cryptoAmount
  );
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [paymentCreatedAt, setPaymentCreatedAt] = useState("");
  const addFundsRef = useRef(null);
  const loadingRef = useRef(null);
  const paymentMethodsRef = useRef(null);
  const upiRef = useRef(null);
  const bankRef = useRef(null);
  const walletRef = useRef(null);
  const successRef = useRef(null);
  const [errors, setErrors] = useState({});
  const statesToRef = (state) => {
    switch (state) {
      case StateEnum.LOADING:
        return loadingRef;
      case StateEnum.ADD_FUNDS:
        return addFundsRef;
      case StateEnum.PAYMENT_METHODS:
        return paymentMethodsRef;
      case StateEnum.BANK_PAYMENT_METHOD:
        return bankRef;
      case StateEnum.UPI_PAYMENT_METHOD:
        return upiRef;
      case StateEnum.INTENT_PAYMENT_METHOD:
        return upiRef;
      case StateEnum.SUCCESS_SCREEN:
        return successRef;
      case StateEnum.WALLET_ADDRESS:
        return walletRef;
    }
  };

  useEffect(() => {
    let currentState = configContext.themeState.currentState;
    configContext.themeState.setCurrentState({
      ...currentState,
      theme: "primary",
    });
  }, []);

  const getTransaction = async () => {
    try {
      const result = external
        ? await getOnRampWithdrawTransaction({
          init: init,
          id: orderId,
        })
        : await getOnRampTransaction({
          init,
          id: orderId,
        });
      if (result?.success) {
        setOrderDetails(result?.data);
        setPaymentDetails(result?.data, external);
        setCurrentState(StateEnum.SUCCESS_SCREEN);
        return StateEnum.SUCCESS_SCREEN;
      }
    } catch (e) { }
  };

  const initializeState = async () => {
    var initialState = StateEnum.LOADING;
    // if (paymentMethod) {
    //   setInputAmount(null);
    //   setSelectedPaymentMethod(paymentMethod);
    // }
    await isUserKYCVerified();
    const currenciesListData = await fetchConversionMethods();
    loadConversionDetails(currenciesListData);

    if (orderId && orderId !== "") {
      return await getTransaction(initialState);
    } else if (cryptoAmount) {
      //get to payment methods page
      initialState = StateEnum.PAYMENT_METHODS;
      // setPriceLock(true);
      //create a price lock order and navigate to benificiery page
    } else {
      if (fiatAmount) {
        //show fiat page with editable values
        initialState = StateEnum.PAYMENT_METHODS;
        // show benificiery page
      }
    }
    //defaul state
    if (initialState === StateEnum.LOADING) {
      initialState = StateEnum.ADD_FUNDS;
    }

    setCurrentState(initialState);

    return initialState;
  };

  const isUserKYCVerified = async () => {
    try {
      const data = await getUser(init);
      if (data?.kyc_status !== "Verified") {
        setIsKycPending(true);
      } else {
        setAccountNumber(data?.bank_accounts?.[0]?.account_number);
        setBankId(data?.bank_accounts?.[0]?.bank_id);
        if (!fiatAmount) {
          return;
        }
      }
    } catch (e) { }
  };

  useEffect(() => {
    setSelectedCryptoCurrency(selectedCurrency?.cryptos?.[0]);
  }, [selectedCurrency]);

  const fetchConversionMethods = async () => {
    try {
      const data = await conversionBuyMenthods(init);
      setCurrenciesList(data);
      return data;
    } catch (err) { }
  };

  const loadConversionDetails = (currenciesList) => {
    setAreConversionDetailsLoading(true);
    const kycCountry = "IND";
    const getDefaultCountry =
      currenciesList?.length &&
      currenciesList.filter(
        (curr) => curr?.countries?.indexOf(kycCountry) !== -1
      );
    setSelectedCurrency(
      getDefaultCountry && getDefaultCountry?.length
        ? getDefaultCountry[0]
        : currenciesList?.length && currenciesList[0]
    );
    fiatAmount
      ? setInputAmount(fiatAmount)
      : setInputAmount(
        getDefaultCountry && getDefaultCountry?.length
          ? getDefaultCountry[0].default_amount
          : currenciesList?.length && currenciesList[0].default_amount
      );
  };

  const amountYouGetData = async () => {
    try {
      setAreConversionDetailsLoading(true);
      if (external) {
        const conversionRequest = await amountYouGetExternal({
          init: init,
          fiat_symbol: "INR",
          network: externalNetwork,
          ...(inputCryptoAmount && { to_amount: inputCryptoAmount }),
          ...(inputAmount && { from_amount: inputAmount }),
          crypto_symbol: cryptoSymbol,
        });
        if (conversionRequest.success) {
          setConversionDetails(conversionRequest.data);
          setAreConversionDetailsLoading(false);
          setInputAmount(Math.ceil(conversionRequest?.data?.from_amount));
          setInputCryptoAmount(conversionRequest?.data?.to_amount);
          setShowCrashError(false);
        } else {
          setAreConversionDetailsLoading(false);
          setShowCrashError(true);
        }
      } else if (selectedCurrency?.fiat_symbol) {
        const conversionRequest = await amountYouGet({
          init: init,
          fiat_symbol: selectedCurrency?.fiat_symbol,
          crypto_symbol: selectedCryptoCurrency?.crypto_symbol,
        });

        if (conversionRequest.success) {
          setInputAmount(1000);
          setInputCryptoAmount(
            (1000 * conversionRequest.data.base_rate).toFixed(2)
          );
          setConversionDetails(conversionRequest.data);
          setAreConversionDetailsLoading(false);
          setShowCrashError(false);
        } else {
          setAreConversionDetailsLoading(false);
          setShowCrashError(true);
        }
      }
    } catch (err) {
      setErrors({
        ...errors,
        conversionError: "Entered amount is less than the minimum amount",
      });
      setShowCrashError(true);
    }
  };

  useEffect(() => {
    if (selectedCryptoCurrency) {
      amountYouGetData();
    }
  }, [selectedCryptoCurrency]);

  const amountYouWillGet =
    inputAmount > 0
      ? parseFloat((inputAmount * conversionDetails?.base_rate)?.toFixed(2))
      : 0;

  const mudrexFees = parseFloat(
    (amountYouWillGet * conversionDetails?.mudrex_fee_factor)?.toFixed(2)
  );
  const tax = parseFloat(
    (amountYouWillGet * conversionDetails?.tax_factor)?.toFixed(2)
  );

  var finalAmountYouGet = 0;
  finalAmountYouGet = (amountYouWillGet - mudrexFees - tax)?.toFixed(2);

  const submitWalletAddress = () => {
    setCurrentState(StateEnum.PAYMENT_METHODS);
  };

  const setPaymentDetails = (data) => {
    setOrderDetails(data);
    setInputAmount(data?.fiat_amount);
    setOrderId(data?.id);
    setPaymentCreatedAt(data?.created_at);
    setPaymentStatus(data?.status);
    setSelectedPaymentMethod(data?.payment_method);
  };

  useEffect(() => {
    const fetchMethods = async () => {
      const kycCountry = "IND";
      const currencyCode =
        selectedCurrency?.fiat_symbol || orderDetails?.fiat_symbol;
      const methodsRequest = await fetchPaymentMethods({
        init: init,
        kycCountry: kycCountry,
        currencyCode: currencyCode,
      });

      if (methodsRequest.success) {
        setPaymentMethods(methodsRequest.data);
      } else {
        setShowCrashError(true);
      }
    };

    try {
      if (selectedCurrency?.fiat_symbol || orderDetails?.fiat_symbol)
        fetchMethods();
    } catch (err) {
      setShowCrashError(true);
    }
  }, [
    selectedCurrency && selectedCurrency?.fiat_symbol,
    orderDetails && orderDetails?.fiat_symbol,
  ]);

  useEffect(() => {
    if (currentState == StateEnum.PAYMENT_METHODS) {
      if (isKycPending) {
        startKYC()
      }
    }
  }, [currentState])

  const states = (state) => {
    switch (state) {
      case StateEnum.LOADING:
        return <LoadingScreen headerVisible={false} />;
      case StateEnum.ADD_FUNDS:
        return (
          <AddFunds
            init={init}
            errors={errors}
            setCurrentState={setCurrentState}
            selectedCurrency={selectedCurrency}
            setSelectedCurrency={setSelectedCurrency}
            inputAmount={inputAmount}
            setInputAmount={setInputAmount}
            conversionDetails={conversionDetails}
            finalAmountYouGet={finalAmountYouGet}
            areConversionDetailsLoading={areConversionDetailsLoading}
            showCrashError={showCrashError}
            checkingLimits={checkingLimits}
            setShowCrashError={setShowCrashError}
            selectedCryptoCurrency={selectedCryptoCurrency}
            isKycPending={isKycPending}
            startKYC={startKYC}
            external={external}
            walletAddress={walletAddress}
            setCheckingLimits={setCheckingLimits}
            inputCryptoAmount={inputCryptoAmount}
            setInputCryptoAmount={setInputCryptoAmount}
            setPaymentDetails={setPaymentDetails}
            externalData={{
              payment_method: paymentMethod,
              to_amount: inputCryptoAmount,
              to_currency: cryptoSymbol,
              from_currency: conversionDetails?.from_currency,
              crypto_wallet_address: walletAddress,
              network: externalNetwork,
              source_id: bankId,
              ...(transactionId && {
                uuid: transactionId,
              }),
            }}
          />
        );
      case StateEnum.WALLET_ADDRESS:
        <WalletScreenContainer
          walletAddress={walletAddress}
          setWalletAddress={setWalletAddress}
          onSubmit={submitWalletAddress}
        />;
      case StateEnum.PAYMENT_METHODS:
        return (
          <ChoosePaymentsMethodContainer
            init={init}
            selectedCurrency={selectedCurrency}
            paymentMethods={paymentMethods}
            setCurrentState={setCurrentState}
            fiatAmount={inputAmount}
            isKycPending={isKycPending}
            startKYC={startKYC}
            setSelectedPaymentMethod={setSelectedPaymentMethod}
            setSelectedPaymentApp={setSelectedPaymentApp}
            bankId={bankId}
            selectedPaymentMethod={selectedPaymentMethod}
            external={external}
            fromAmount={inputAmount}
            toAmount={inputCryptoAmount}
            orderId={orderId}
            setPaymentDetails={setPaymentDetails}
            walletAddress={walletAddress}
            externalNetwork={externalNetwork}
            transactionId={transactionId}
          />
        );
      case StateEnum.BANK_PAYMENT_METHOD:
        return (
          <BankTransferContainer
            init={init}
            selectedCurrency={selectedCurrency}
            inputAmount={inputAmount}
            finalAmountYouGet={finalAmountYouGet}
            paymentMethods={paymentMethods}
            bankId={bankId}
            accountNumber={accountNumber}
            onSuccess={onSuccess}
            external={external}
            externalNetwork={externalNetwork}
            walletAddress={walletAddress}
            orderId={orderId}
            orderDetails={orderDetails}
            cryptoAmount={inputCryptoAmount}
            setPaymentDetails={setPaymentDetails}
            setCurrentState={setCurrentState}
            transactionId={transactionId}
          />
        );
      case StateEnum.UPI_PAYMENT_METHOD:
        return (
          <UPITransferContainer
            init={init}
            selectedCurrency={selectedCurrency}
            inputAmount={inputAmount}
            finalAmountYouGet={finalAmountYouGet}
            paymentMethods={paymentMethods}
            bankId={bankId}
            accountNumber={accountNumber}
            onSuccess={onSuccess}
            external={external}
            externalNetwork={externalNetwork}
            walletAddress={walletAddress}
            orderId={orderId}
            orderDetails={orderDetails}
            setPaymentDetails={setPaymentDetails}
            cryptoAmount={inputCryptoAmount}
            setCurrentState={setCurrentState}
            transactionId={transactionId}
            selectedPaymentApp={selectedPaymentApp}
          />
        );
      case StateEnum.INTENT_PAYMENT_METHOD:
        return (
          <UpiIntentContainer
            init={init}
            selectedCurrency={selectedCurrency}
            inputAmount={inputAmount}
            finalAmountYouGet={finalAmountYouGet}
            paymentMethods={paymentMethods}
            bankId={bankId}
            accountNumber={accountNumber}
            onSuccess={onSuccess}
            external={external}
            externalNetwork={externalNetwork}
            walletAddress={walletAddress}
            orderId={orderId}
            orderDetails={orderDetails}
            setPaymentDetails={setPaymentDetails}
            cryptoAmount={inputCryptoAmount}
            setCurrentState={setCurrentState}
            transactionId={transactionId}
            selectedPaymentApp={selectedPaymentApp}
          />
        );
      case StateEnum.SUCCESS_SCREEN:
        return (
          <OffRampSuccess
            orderDetails={orderDetails}
            onSuccess={onSuccess}
            paymentMethod={selectedPaymentMethod}
            setPaymentMethod={setSelectedPaymentMethod}
            amount={inputAmount}
            createdAt={paymentCreatedAt}
            status={paymentStatus}
            getTransaction={getTransaction}
            external={external}
            item={item}
          />
        );
    }
  };

  useEffect(() => {
    initializeState();
  }, []);

  return (
    <SwitchTransition mode={"out-in"}>
      <CSSTransition
        key={currentState}
        nodeRef={statesToRef(currentState)}
        addEndListener={(done) => {
          statesToRef(currentState).current.addEventListener(
            "transitionend",
            done,
            false
          );
        }}
        classNames="fade"
      >
        <div
          id={currentState}
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            overflow: "scroll",
          }}
          ref={statesToRef(currentState)}
        >
          {states(currentState)}
        </div>
      </CSSTransition>
    </SwitchTransition>
  );
};

export default OnRamp;
