import { Typography } from "@mui/material";
import upiMethodGooglePay from "assets/images/gpay.png";
import upiMethodPaytm from "assets/images/paytm.png";
import upiMethodPhonePe from "assets/images/phonepe.png";
import upiMethod from "assets/images/upi-method.png";
import { FC, useEffect, useState } from "react";
import colors from "styles/colors";
import { TriggerScraper } from "../handlers/triggerScraper";
import StateEnum from "../states";

type PostIntentOpenProps = {
    inputAmount: number
    selectedPaymentApp: string
    setCurrentState: (state: any) => void
}

const payment_icons = (methodName: string) => {
    switch (methodName) {
        case "GOOGLEPAY":
            return upiMethodGooglePay;
        case "PAYTM":
            return upiMethodPaytm;
        case "PHONEPE":
            return upiMethodPhonePe;
        default:
            return upiMethod;
    }
};

export const PostIntentOpen: FC<PostIntentOpenProps> = (props) => {

    // const [error, setError] = useState("");
    // const [loading, setLoading] = useState(false);

    useEffect(() => {
        const triggerScraper = async () => {
            const request = await TriggerScraper();
            props.setCurrentState(StateEnum.SUCCESS_SCREEN)
        }

        triggerScraper()
    })

    return <div
        style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            height: "100%"
        }}
    >
        <div style={{ textAlign: "center" }}>
            <img
                alt="payment-method"
                style={{
                    height: 35,
                    borderWidth: 1,
                    //   borderColor: colors.Grey.borderGrey,
                    borderRadius: 6,
                }}
                src={payment_icons(props.selectedPaymentApp)}
            />
            <Typography
                sx={{ fontSize: 16 }}
                style={{ fontWeight: 700, paddingTop: 5 }}
            >
                Getting your payment
            </Typography>
        </div>
    </div>
}

export default PostIntentOpen