import React from "react";
import { isTextNull } from "utils/validators";
import { TextField, Alert, InputAdornment, Typography } from "@mui/material";
import colors from "styles/colors";

const KycTextInput = ({
  text,
  setText,
  placeholder,
  leading,
  trailing,
  maxLength,
  errorText,
  keyboardType,
  isEditable = true,
  autoCapitalize = true,
  style = {},
  textStyle = {},
}) => {
  return (
    <div>
      <div>
        <TextField
          variant="outlined"
          disabled={!isEditable}
          inputProps={{
            style: {
              borderWidth: 1,
              borderColor: colors.Dark.title,
              backgroundColor: colors.Background.secondary,
              ...(autoCapitalize && { textTransform: "uppercase" }),
              ...style,
              ...textStyle,
            },
            maxLength: maxLength,
          }}
          sx={{ width: "100%", marginTop: "10px", marginBottom: "10px" }}
          size="small"
          onInput={(e) => {
            if (keyboardType === "number") {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, maxLength);
            }
          }}
          onChange={(event) => {
            setText(
              autoCapitalize
                ? event.target.value.toUpperCase()
                : event.target.value
            );
          }}
          value={text}
          placeholder={placeholder}
          type={keyboardType}
          InputProps={{
            startAdornment: leading && (
              <InputAdornment position="start">{leading}</InputAdornment>
            ),
            endAdornment: trailing && (
              <InputAdornment position="end">{trailing}</InputAdornment>
            ),
          }}
        />
      </div>
      {!isTextNull(errorText) && (
        <div style={{ marginBottom: 10 }}>
          <Alert severity="error">
            <Typography variant="alert">{errorText}</Typography>
          </Alert>
        </div>
      )}
    </div>
  );
};

export default KycTextInput;
