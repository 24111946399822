import BaseLayout from "components/shared/BaseLayout";
import KycTextInput from "components/shared/KycTextInput";
import React, { useState } from "react";
import MHeading from "components/shared/MHeading";
import MButton from "components/shared/MButton";
import ConfirmPhone from "components/kyc/ConfirmPhone";
import MBottomSheet from "components/shared/BottomSheet";
const phoneNumberRegex = /^\d{1,10}$/;

const PhoneVerification = ({
  phone,
  setPhone,
  isBottomsheetVisible,
  setIsBottomsheetVisible,
  onSubmitPhone,
  error,
  setError,
  loading,
  bottomsheetLoading,
  otp,
  setOtp,
  onSubmitPhoneOtp,
  onReenterPhone,
  onResendOtp,
  otpError,
  otpSuccess,
  setOtpError,
  setOtpSucces,
  resendLoading,
  otpTimer,
  setOtpTimer,
}) => {
  const [isValidPhone, setIsValidPhone] = useState(false);
  return (
    <BaseLayout
      theme={"primary"}
      headerTitle={"Verify Account"}
      headerVisible={true}
      body={
        <div id="body">
          <div id="body">
            <MHeading
              heading={"Enter your phone"}
              subHeading={"We need your phone number to proceed further"}
            />
            <KycTextInput
              placeholder={"Enter Phone"}
              leading="+91"
              text={phone}
              setText={(text) => {
                if (!phoneNumberRegex.test(text)) {
                  setError("Invalid Phone Number");
                  setIsValidPhone(false);
                } else {
                  setError(null);
                  setIsValidPhone(true);
                }
                setPhone(text);
              }}
              autoCapitalize={false}
              keyboardType="number"
              errorText={error}
              maxLength={10}
            />
          </div>
        </div>
      }
      footer={
        <div id="footer">
          <MButton
            disabled={loading || !phone || phone?.length < 10 || !isValidPhone}
            onPress={onSubmitPhone}
            text={"Continue"}
            isPurpleButton
            size="medium"
            loading={loading}
          />
        </div>
      }
      bottomSheet={
        <MBottomSheet
          isVisible={isBottomsheetVisible}
          close={() => {
            setIsBottomsheetVisible(false);
          }}
          title="Confirm your phone"
        >
          <ConfirmPhone
            otp={otp}
            setOtp={setOtp}
            onSubmitPhoneOtp={onSubmitPhoneOtp}
            isLoading={bottomsheetLoading}
            onReenterPhone={onReenterPhone}
            onResendOtp={onResendOtp}
            phone={phone}
            otpError={otpError}
            otpSuccess={otpSuccess}
            setOtpError={setOtpError}
            setOtpSucces={setOtpSucces}
            resendLoading={resendLoading}
            otpTimer={otpTimer}
            setOtpTimer={setOtpTimer}
          />
        </MBottomSheet>
      }
    />
  );
};

export default PhoneVerification;
