import Loader from "assets/lottie/loader.json";
import { ConfigContext } from "context/ConfigContext";
import { useContext } from "react";
import ReactLoading from "react-loading";
import Lottie from "react-lottie";
import gs from "../../../styles/styles";
import BaseLayout from "../BaseLayout";

const LoadingScreen = ({ headerVisible = true }) => {
  return (
    <BaseLayout
      theme={"primary"}
      headerVisible={headerVisible}
      brandingVisible={false}
      body={
        <LoadingView />
      }
    />
  );
};

export const LoadingView = () => {

  const configContext = useContext(ConfigContext);
  const globalStyles = gs();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className={globalStyles.containerCenter}>
      {configContext?.config?.theme ||
        Object.keys(configContext?.config).length === 0 ? (
        <ReactLoading
          type={"bars"}
          color={
            configContext?.config?.theme?.palette?.primary
              ? configContext?.config?.theme?.palette?.primary
              : "#00000"
          }
        />
      ) : (
        <Lottie options={defaultOptions} height={70} width={70} />
      )}
    </div>)
}

export default LoadingScreen;
