import themeOptions from "styles/theme";
import { ThemeProvider } from "@mui/material/styles";
import Caas from "pages/caas";
import { useSearchParams } from "react-router-dom";

const OnRamp = ({}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const init = async () => {
    return {
      signature: searchParams.get("secret"),
      timestamp: searchParams.get("timestamp"),
      userId: searchParams.get("user_id"),
      clientId: searchParams.get("client_id"),
      mode: "DEV",
      hv_token: searchParams.get("hv_token"),
    };
  };

  return (
    <>
      <ThemeProvider theme={themeOptions}>
        <Caas
          init={init}
          sdkConfiguration={{
            steps: ["onramp"],
            kycEnabled: true,
            bankEnabled: true,
          }}
          onKYCSuccessCallback={() => {}}
          onRampSuccessCallback={() => {}}
          offRampSuccessCallback={() => {}}
          onExceptionCallback={() => {
            window.location.reload();
          }}
          exceptionCTA={"Try again"}
          fiatAmount={searchParams.get("fiat_amount")}
          startCustomKYCCallback={() => {}}
          onBack={() => {}}
          withdrawNetwork={searchParams.get("network")}
          withdrawAddress={searchParams.get("wallet_address")}
          transactionId={searchParams.get("transaction_id")}
          redirectUrl={searchParams.get("redirect_url")}
          cryptoAmount={searchParams.get("crypto_amount")}
          cryptoSymbol={searchParams.get("crypto_symbol")}
          orderId={searchParams.get("order_id")}
          expiresAt={searchParams.get("expires_at")}
          paymentMethod={searchParams.get("payment_method")}
        />
      </ThemeProvider>
    </>
  );
};

export default OnRamp;
